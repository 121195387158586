import clsx from "clsx";
import LystLogo from "img/branding/lyst-logo-2024.svg?inline";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { JsonLd } from "react-schemaorg";
import { WebSite } from "schema-dts";
import { KevelCampaignControl } from "web/react/components/kevel-campaign-control/kevel-campaign-control";
import { CountrySelector } from "web/react/components/navigation/header-bar/country-selector";
import { HeaderGenders } from "web/react/components/navigation/header-bar/header-genders/header-genders";
import { LanguageSelector } from "web/react/components/navigation/header-bar/language-selector";
import { SearchButton } from "web/react/components/navigation/header-bar/search-button";
import { UserDropdown } from "web/react/components/navigation/header-bar/user-dropdown";
import { UserDropdownProps } from "web/react/components/navigation/header-bar/user-dropdown/user-dropdown";
import { MainNavigationToggleMobile } from "web/react/components/navigation/navigation-menu/main-navigation-toggle-mobile/main-navigation-toggle-mobile";
import { useNavigationContext } from "web/react/components/navigation/navigation.context";
import { SearchBar } from "web/react/components/navigation/search-bar";
import { AutoSuggestProvider } from "web/react/components/navigation/search-bar/auto-suggest/auto-suggest.context";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { HStack } from "web/react/emo/h-stack";
import { StatusIndicator } from "web/react/emo/status-indicator";
import { View } from "web/react/emo/view";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import globals from "web/script/modules/globals";
import { canUseMembership } from "web/script/modules/utils";
import {
    AppHeaderBarSerializer,
    AppPreHeaderBarLanguageSelectorSerializer,
} from "web/types/serializers";
import * as styles from "./header-bar.css";

export interface HeaderBarProps extends AppHeaderBarSerializer {
    userDropdown: UserDropdownProps;
    registerUrl: string;
    showCountryPicker: boolean;
    languageSelector: AppPreHeaderBarLanguageSelectorSerializer;
}

// This is a workaround for the fact that the JsonLd potentialAction property
// does not include the "query-input" property
type CustomWebSite = WebSite & {
    potentialAction: {
        "@type": "SearchAction";
        target: string;
        "query-input": string;
    };
};

/**
 * Design System
 * @name HeaderBar
 * @version 1.0
 * @design https://www.figma.com/file/VhOHx4tv9uYXZHXAAKPVYf/Design-System?type=design&node-id=6158-67258&t=yB3Ayvy32oTl0C0n-4
 */

export function HeaderBar({
    home_url: homeUrl,
    is_homepage: isHomePage,
    genders,
    is_authenticated: isAuthenticated,
    wishlist_url: savedItemUrl,
    notifications_url: notificationsUrl,
    notifications_count: notificationsCount,
    search_input: searchInput,
    absolute_home_url: absoluteHomeUrl,
    absolute_action_url: absoluteActionUrl,
    registerUrl,
    userDropdown,
    country_info: countryInfo,
    showCountryPicker,
    languageSelector,
}: HeaderBarProps): React.ReactElement {
    const [currentPage, setCurrentPage] = useState<string | null>(null);

    const { showMenu, showSearch, handleHeaderAnalytics } = useNavigationContext();
    const { isDesktopViewport } = useDomViewport();

    function navigateToHomePage(): void {
        globals.window.location.href = homeUrl;
    }

    function renderLogo(): React.ReactElement {
        return (
            <KevelCampaignControl
                captureType="header_logo"
                contextType={"header_logo"}
                analyticsEventLabel={"header_logo"}
                defaultAction={navigateToHomePage}
                continueOptionHref={homeUrl}
            >
                <a className={styles.logo} href={homeUrl}>
                    <LystLogo width={"100%"} height={"100%"} />
                </a>
            </KevelCampaignControl>
        );
    }

    useEffect(() => {
        if (window.location.pathname === notificationsUrl) {
            setCurrentPage("alerts");
        } else if (window.location.pathname === savedItemUrl) {
            setCurrentPage("wishlist");
        }
    }, [notificationsUrl, savedItemUrl]);

    return (
        <div className={styles.container}>
            <View width="full" className={styles.content}>
                <HStack
                    spacing={isDesktopViewport ? "md" : "none"}
                    justify="space-between"
                    align="center"
                >
                    <View width={{ lg: "full" }}>
                        <HStack spacing={isDesktopViewport ? "lg" : "none"} align="center">
                            <div className={styles.hideMobileAndTablet}>{renderLogo()}</div>
                            <HeaderGenders genders={genders} />

                            <div className={clsx(styles.hideDesktop, styles.iconContainer)}>
                                <MainNavigationToggleMobile />
                            </div>

                            <View
                                width={{ lg: "full" }}
                                className={isDesktopViewport ? undefined : styles.iconContainer}
                            >
                                <AutoSuggestProvider>
                                    {isDesktopViewport && (
                                        <SearchBar searchInput={searchInput} isMobile={false} />
                                    )}
                                    <div className={styles.hideDesktop}>
                                        <SearchButton isMobile />
                                    </div>
                                    {/** Use portal to render into div if not desktop @see navigation.tsx */}
                                    {showSearch === "mobile" &&
                                        ReactDOM.createPortal(
                                            <View padding="sm">
                                                <SearchBar searchInput={searchInput} isMobile />
                                            </View>,
                                            document.getElementById(
                                                "app-header-search-bar-mobile"
                                            ) as HTMLDivElement
                                        )}
                                    {isHomePage && (
                                        <JsonLd<CustomWebSite>
                                            item={{
                                                "@context": "https://schema.org",
                                                "@type": "WebSite",
                                                url: absoluteHomeUrl,
                                                potentialAction: {
                                                    "@type": "SearchAction",
                                                    target: `${absoluteActionUrl}?q={q}`,
                                                    "query-input": "name=q",
                                                },
                                            }}
                                        />
                                    )}
                                </AutoSuggestProvider>
                            </View>
                        </HStack>
                    </View>

                    <div className={styles.hideDesktop}>{renderLogo()}</div>

                    <HStack spacing="md" align="center">
                        {canUseMembership() ? (
                            <HStack spacing={isDesktopViewport ? "none" : "xxxs"} align="center">
                                <a
                                    className={clsx(styles.iconContainer, {
                                        [styles.iconActive]: currentPage === "alerts",
                                    })}
                                    href={
                                        isAuthenticated && notificationsUrl
                                            ? notificationsUrl
                                            : registerUrl
                                    }
                                    onClick={() => {
                                        handleHeaderAnalytics("alerts_icon");
                                    }}
                                    data-testid="alerts"
                                >
                                    <SVGIcon
                                        name={
                                            currentPage === "alerts"
                                                ? "bell-nav-filled"
                                                : "bell-nav"
                                        }
                                        className={clsx({
                                            [styles.focusIcon]: currentPage === "alerts",
                                            [styles.iconGrey]: showMenu || showSearch,
                                        })}
                                    />
                                    <StatusIndicator active={!!notificationsCount} />
                                </a>

                                {savedItemUrl && (
                                    <a
                                        className={clsx(styles.iconContainer, {
                                            [styles.iconActive]: currentPage === "wishlist",
                                        })}
                                        href={savedItemUrl}
                                        onClick={() => {
                                            handleHeaderAnalytics("wishlist_icon");
                                        }}
                                        data-testid="wishlist"
                                    >
                                        <SVGIcon
                                            name={
                                                currentPage === "wishlist"
                                                    ? "heart-icon-nav-filled"
                                                    : "heart-icon-nav"
                                            }
                                            className={clsx({
                                                [styles.focusIcon]: currentPage === "wishlist",
                                                [styles.iconGrey]: showMenu || showSearch,
                                            })}
                                        />
                                    </a>
                                )}
                            </HStack>
                        ) : (
                            <>
                                <div className={clsx(styles.hideDesktop, styles.iconContainer)} />
                                <div className={clsx(styles.hideDesktop, styles.iconContainer)} />
                            </> /* maintain flex layout for non-english mobile header bar */
                        )}
                        <div className={styles.hideMobileAndTablet}>
                            <HStack spacing="sm" align="center">
                                {canUseMembership() && <UserDropdown {...userDropdown} />}
                                <CountrySelector
                                    countryInfo={countryInfo}
                                    showCountryPicker={showCountryPicker}
                                />
                                {languageSelector.is_shown && (
                                    <LanguageSelector
                                        calloutText={languageSelector.callout_text}
                                        choices={languageSelector.choices}
                                    />
                                )}
                            </HStack>
                        </div>
                    </HStack>
                </HStack>
            </View>
        </div>
    );
}
