import React from "react";
import { HStack } from "web/react/emo/h-stack";
import { Heading } from "web/react/emo/heading";
import { Text } from "web/react/emo/text";
import { VStack } from "web/react/emo/v-stack";

import clsx from "clsx";
import { Conditional } from "web/react/components/conditional";
import * as styles from "web/react/components/rail/rail.css";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { Button } from "web/react/emo/button";
import { gettext } from "web/script/modules/django-i18n";

interface RailButtonProps {
    railRef: React.RefObject<HTMLOListElement>;
    direction: "left" | "right";
    onClick: () => void;
    isAtStart: boolean;
    hasReachedEnd: boolean;
}

function RailButton({
    railRef,
    direction,
    onClick,
    isAtStart,
    hasReachedEnd,
}: RailButtonProps): JSX.Element {
    function move(): void {
        const target = railRef?.current;
        if (target) {
            const scrollFactor = direction === "left" ? -1 : 1;
            const scrollAmount = scrollFactor * (target.offsetWidth / 1.5);

            onClick();
            target.scrollLeft += scrollAmount;
        }
    }

    const ariaLabel =
        direction === "left" ? gettext("general.previous_btn") : gettext("general.next_btn");
    const dataTestId = `${direction === "left" ? "previous" : "next"}-btn`;

    return (
        <button aria-label={ariaLabel} onClick={move} data-testid={dataTestId}>
            <SVGIcon
                className={clsx(styles.button, {
                    [styles.leftButton]: direction === "left",
                    [styles.disabledButton]: direction === "left" ? isAtStart : hasReachedEnd,
                })}
                name={"chevron"}
            />
        </button>
    );
}

interface RailHeaderProps {
    title?: string;
    caption?: string;
    isScrollable: boolean;
    railRef: React.RefObject<HTMLOListElement>;
    scrollInteraction: () => void;
    isAtStart: boolean;
    hasReachedEnd: boolean;
    ctaUrl?: string;
    onCtaClick?: (any?) => void;
    variant?: "primary" | "secondary";
}

export function RailHeader({
    title,
    caption,
    isScrollable,
    railRef,
    scrollInteraction,
    isAtStart,
    hasReachedEnd,
    ctaUrl,
    onCtaClick,
    variant = "primary",
}: RailHeaderProps): JSX.Element {
    const isSecondaryVariant = variant === "secondary";
    return (
        <div className={styles.railHeader[variant]}>
            {!!title && (
                <VStack spacing="xs">
                    <Heading
                        as={"h4"}
                        textStyle={
                            isSecondaryVariant
                                ? { sm: "title-2", lg: "large-title-2" }
                                : { sm: "title-3", md: "title-2" }
                        }
                    >
                        {title}
                    </Heading>
                    {caption && (
                        <Text color="secondary" textStyle="body-3-small">
                            {caption}
                        </Text>
                    )}
                </VStack>
            )}
            {isScrollable && (
                <HStack spacing="lg" align="center">
                    <div className={styles.navigationButtons[variant]}>
                        <RailButton
                            railRef={railRef}
                            direction="left"
                            onClick={scrollInteraction}
                            isAtStart={isAtStart}
                            hasReachedEnd={hasReachedEnd}
                        />
                        <RailButton
                            railRef={railRef}
                            direction="right"
                            onClick={scrollInteraction}
                            isAtStart={isAtStart}
                            hasReachedEnd={hasReachedEnd}
                        />
                    </div>
                    <Conditional check={!!ctaUrl && isSecondaryVariant}>
                        <div data-testid="rail-cta" className={styles.seeAllButton}>
                            <Button
                                variant="secondary"
                                title={gettext("general.see_all")}
                                href={ctaUrl}
                                onClick={onCtaClick}
                                width="full"
                            />
                        </div>
                    </Conditional>
                </HStack>
            )}
        </div>
    );
}
