import AnalyticsCookie from "@lyst/lyst-analytics-cookie";
import _clone from "lodash/clone";
import _extend from "lodash/extend";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";
import _isFunction from "lodash/isFunction";
import _isString from "lodash/isString";
import _map from "lodash/map";
import { watchState } from "web/redux/state-watcher";
import store from "web/redux/store";
import eventEmitter from "web/script/mixins/event-emitter";
import environment from "web/script/modules/environment";
import globals from "web/script/modules/globals";
import browser from "web/script/utils/browser";
import storage from "web/script/utils/storage";
import uuid from "web/script/utils/uuid";
import agifTransport from "./transports/agif-transport";
import bingConversionTransport from "./transports/bing-conversion-transport";
import brazeAnalyticsTransport from "./transports/braze-analytics-transport";
import criteoTransport from "./transports/criteo-transport";
import facebookTagTransport from "./transports/facebook-tag-transport";
import googleAnalytics4Transport from "./transports/google-analytics-4-transport";
import googleAnalyticsTransport from "./transports/google-analytics-transport";
import googleConsentModeTransport from "./transports/google-consent-mode-transport";
import googleConversionTransport from "./transports/google-conversion-transport";
import internalPageViewTransport from "./transports/internal-page-view-transport";
import kelkooTransport from "./transports/kelkoo-transport";
import microsoftClarityTransport from "./transports/microsoft-clarity-transport";
import microsoftConsentModeTransport from "./transports/microsoft-consent-mode-transport";
import pinterestTransport from "./transports/pinterest-transport";
import snapchatTransport from "./transports/snapchat-transport";
import tiktokTransport from "./transports/tiktok-transport";
import yahooGeminiTransport from "./transports/yahoo-gemini-transport";
import { generateEventData } from "./utils";

/**
 * Current cookie version. Keep in Sync with analytics middleware
 * @type {number}
 */
const COOKIE_VERSION = parseInt(environment.get("analyticsCookieVersion", "1"), 10);

/**
 * Function to take a URLSearchParams object and get the last entry for a given key
 * Reason for doing that is that we often get paid links with multiple versions of atc_ *
 * parameters. If the key does not exist, it returns null
 * @returns {(string|null)}
 */
function getLastParamOrNull(params, key) {
    return params.getAll(key).slice(-1)[0] || null;
}

function extractCookieConsentState(state) {
    return state.cookieConsentReducer;
}

function getCookieConsentFromState(cookieConsentReducer) {
    return {
        advertisement: cookieConsentReducer.acceptsAds,
        customization: cookieConsentReducer.acceptsCustomization,
        analytics: cookieConsentReducer.acceptsAnalytics,
        consentConfirmed: cookieConsentReducer.consentConfirmed,
    };
}

class Analytics {
    constructor() {
        // record of the latest tracking event fired
        this.latestEvent = "";

        this._initInitialPageType(environment.get("pageType"));
        this._initInitialSubPageType(environment.get("pageSubType"));

        // Get initial cookie consent levels from redux store
        const initialCookieConsentReducer = extractCookieConsentState(store.getState());
        this.cookieConsent = getCookieConsentFromState(initialCookieConsentReducer);

        // Watch for the cookie consent state to change
        watchState(extractCookieConsentState, (cookieConsentReducer) => {
            this.cookieConsent = getCookieConsentFromState(cookieConsentReducer);

            this._initTransports();
            this._sendThirdPartyData();
        });

        // Only send page timing data on first pageview after load.
        this._pageLoadTimingDataSent = false;

        // Don't increment the pageViewsCount until after the first pageViews() call
        this._allowPageViewsCountIncrement = false;

        this.init = function () {
            // this reads data from local storage and the analytics cookie
            this._setInitialState();

            this._initTransports();

            this._send = this._send.bind(this);

            // Browser time from page load, to get more correct event_time
            this._browserTimeStart = new Date();

            // Initial referrer is the document referrer, but this may change while the user is on the page.
            // For example if they open up an overlay or the page changes without reload e.g. search
            this.referrer = document.referrer;
        };
    }
    on = eventEmitter.on;
    off = eventEmitter.off;
    trigger = eventEmitter.trigger;

    /**
     * Array of pending Promises that should be
     * resolved when all `pendingData` has been sent.
     * @type {Promise[]}
     */
    pendingPromiseResolvers = [];

    /**
     * Array of pending data objects. Each object will
     * have `type` and `data` properties.
     * @type {Object[]}
     */
    pendingData = [];

    /**
     * Array of pending third party data objects. Each object will
     * have `type` and `data` properties.
     * @type {Object[]}
     */
    pendingThirdPartyData = [];

    /**
     * The document referrer. Updated when a page view is tracked for client side rendered pages.
     * @type {string}
     */
    referrer = null;

    /**
     * Array of transport objects.
     *
     * A transport is an object that implements a `send` method and
     * knows how to send data to a particular service.
     *
     * This method will be called with an array of pending data objects,
     * each with `type` and `data` properties. It should always return
     * a Promise that is resolved when the data it needed to send has
     * been successfully sent.
     *
     * Some transports can be enabled/disabled by the user depending on their
     * cookie preferences. The cookie consent levels can be mapped to
     * these different type of transports.
     *
     * When new transports are added that create new cookies then that cookie
     * version needs to be incremented in src/ssaw/utils/constants/cookie_consent.py
     * This will invalidate older cookies and require renewed consent from
     * the user.
     *
     * @type {Object[]}
     */
    transports = [];
    essentialTransports = [agifTransport, internalPageViewTransport];
    analyticsTransports = [
        googleAnalytics4Transport,
        googleAnalyticsTransport,
        googleConsentModeTransport,
        facebookTagTransport,
        brazeAnalyticsTransport,
        microsoftClarityTransport,
    ];
    adTransports = [
        googleConversionTransport,
        googleConsentModeTransport,
        bingConversionTransport,
        yahooGeminiTransport,
        criteoTransport,
        snapchatTransport,
        kelkooTransport,
        pinterestTransport,
        tiktokTransport,
        microsoftConsentModeTransport,
    ];

    /**
     * The id of the setTimeout used to schedule a send.
     * @type {Number}
     */
    sendTimeout = null;

    TAG_NETWORKS = {
        CRITEO: "criteo",
    };

    /**
     * Returns an array of all available transports
     * @returns {Array}
     */
    _getTransports() {
        let transports = this.essentialTransports;
        if (environment.get("disable_third_party_js") === true) {
            return transports;
        }

        transports = [...transports, ...this._getThirdPartyTransports()];

        return transports;
    }

    /**
     * Returns an array of available third party transports
     * @returns {Array}
     */
    _getThirdPartyTransports() {
        let transports = [];
        if (this.cookieConsent.analytics) {
            transports = [...transports, ...this.analyticsTransports];
        }
        if (this.cookieConsent.advertisement) {
            transports = [...transports, ...this.adTransports];
        }

        return transports;
    }

    /**
     * Adds data to the pendingData queue and returns a Promise
     * that is resolved when the data is sent.
     * @param data
     * @returns {Promise}
     * @private
     */
    _queueData(data) {
        this.pendingData.push(data);

        return this._addPromiseAndScheduleSend();
    }

    /**
     * Returns a Promise that is resolved when the queue is next empty.
     * If the queue is already empty a resolved Promise will be returned.
     * @returns {Promise}
     */
    whenQueueIsEmpty() {
        // if consent isn't confirmed then we don't care about the third party data queue being empty
        if (
            this.pendingData.length === 0 &&
            (!this.cookieConsent.consentConfirmed || this.pendingThirdPartyData.length === 0)
        ) {
            return Promise.resolve();
        }

        return new Promise((resolve) => {
            this.pendingPromiseResolvers.push(resolve);
        });
    }

    /**
     * Tracks an event.
     * @param {string} category The event category.
     * @param {string} action The event action.
     * @param {string} [label=""] Optional free text label for the event.
     * @param {Boolean} [nonInteraction=false] This field is used to indicate that the event
     *                                         is not the result of the user interacting with
     *                                         the page. Non-interactive events will not effect
     *                                         bounce rate, use it for things like a pop-up being
     *                                         triggered automatically.
     * @param {dict} [customData=null] Optional information if you need to send customised data.
     * @param {string} [subType=""] SubType for event.
     * @param {CheckoutData} [checkoutData={}] Custom data for checkout conforming to CheckoutData type.
     * @param {array|object} [itemData=[]] Context dependant data.
     * @param {Boolean} [lock=false] Whether we should 'lock' this specific event until another event
     *                               is fired, so that we don't repeat events unnecessarily.
     **/
    event(
        category,
        action,
        label = "",
        nonInteraction = false,
        customData = {},
        subType = "",
        checkoutData = {},
        itemData = [],
        lock = false,
        truncate = true
    ) {
        if (!_isString(category) || !_isString(action)) {
            console.error("Both category and action must be strings.");
            return Promise.resolve();
        }

        const eventData = generateEventData(
            category,
            action,
            label,
            nonInteraction,
            customData,
            subType,
            checkoutData,
            itemData,
            truncate
        );

        const previousEvent = this.latestEvent;

        const eventIdentifier = `${category}.${action}.${label}`;
        this.latestEvent = eventIdentifier;

        if (lock && previousEvent === eventIdentifier) {
            return Promise.resolve();
        }

        return this._queueData({
            type: "event",
            data: this._addMetaData(eventData),
        });
    }

    /**
     * Track an ecommerce action using the GA enhanced ecommerce tracking.
     * This method takes the same arguments as the `ga` function.
     * See https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce for more info.
     * @param args
     * @returns {Promise}
     */
    ecommerce(...args) {
        return this._queueData({
            type: "ecommerce",
            data: args,
        });
    }

    /**
     * Fire retargetting data to Google Adwords. The conversion ID and conversion label
     * are provided by Google Adwords tag set up. Google Ads will provide some HTML code
     * to put on a page to do retargeting. Ignore most of it, but get the conversion ID and
     * label from it.
     *
     * If conversion is not set up correctly, then it will be silently ignored.
     *
     * This function comes from Google's conversion_async.js, which is loaded through
     * Google Tag Manager. Use this function to handle custom events that the Analytics
     * team otherwise cannot target.
     * @param conversionId
     * @param conversionLabel
     */
    retarget(conversionId, conversionLabel) {
        return this._queueData({
            type: "adwords_retargetting",
            data: {
                google_conversion_id: conversionId,
                google_conversion_label: conversionLabel,
            },
        });
    }

    /**
     * Tracks a page view.
     * @param {array} [itemData=[]] Context dependant data.
     */
    pageView(itemData = []) {
        // ORG-3513: Don't send page view event
        // if the initial page type is not product overlay
        // but we are opening hash URL which opens product overlay
        // see https://confluence.lystit.com/display/OA/FY23+Q2+-+Hash+URL+Crawl+Optimisation
        const pageType = environment.get("pageType");

        // We must be sure that the hash in the URL
        // actually opens product overlay so we can block the page view event
        // otherwise we might end up blocking events of URLs
        // which have hash but are not opening overlay
        const overlayOnInitialPageLoad = storage.get("overlayOnInitialPageLoad", false, true);
        const url = this._getURL();

        if (pageType !== "product_overlay" && overlayOnInitialPageLoad && url.hash) {
            return;
        }

        // update the page view count and generate a new page view id
        if (this._allowPageViewsCountIncrement) {
            this.options.pageViewCount = this.getPageViewsCount() + 1;
        } else {
            this._allowPageViewsCountIncrement = true;
        }

        // update analytics cookie
        this.updatePageViewInAnalyticsCookie();

        let data = this._getPageViewData();
        this._saveTrafficParameters(data);

        // some data may be stored in the environment
        let envData = environment.get("pageViewAnalytics");
        if (envData) {
            _extend(data, envData);
        }

        if (!_isEmpty(itemData)) {
            data.item_data = itemData;
        }

        const supportsNTAPI =
            window.performance && window.performance.now && window.performance.timing;

        if (!this._pageLoadTimingDataSent && supportsNTAPI) {
            let perf = window.performance.timing;

            let now = Math.round(perf.navigationStart + window.performance.now());
            let loadTimingData = {
                ttfb_ms: perf.responseStart - perf.fetchStart,
                page_load_ms: now - perf.responseStart,
            };

            if (loadTimingData.ttfb_ms > 0 && loadTimingData.page_load_ms > 0) {
                _extend(data, loadTimingData);
            }

            this._pageLoadTimingDataSent = true;
        }

        const sendPromise = this._queueData({
            data,
            type: "page_view",
        });

        this.setReferrer(url.href, this.options.pageViewId);

        return sendPromise;
    }

    /**
     * Gathers UTM/ATC attribution parameters from pageViewData and stores them in
     * local storage if they have been updated or if the session_id has changed.
     * Enables persistence of UTM/ATC parameters after navigating away from
     * landing pages where those parameters were originally set.
     * @param pageViewData - pageViewData object as returned by _getPageViewData()
     */
    _saveTrafficParameters(pageViewData) {
        let existingTrafficParameters = storage.get("TrafficParameters");
        let trafficParameters = {
            session_id: pageViewData.session_id,
            attribution: {
                utm_medium: pageViewData.utm_medium,
                utm_campaign: pageViewData.utm_campaign,
                utm_source: pageViewData.utm_source,
                utm_content: pageViewData.utm_content,
                utm_term: pageViewData.utm_term,
                utm_country: pageViewData.utm_country,
                utm_grouping: pageViewData.utm_grouping,
                utm_keywords: pageViewData.utm_keywords,

                atc_medium: pageViewData.atc_medium,
                atc_campaign: pageViewData.atc_campaign,
                atc_country: pageViewData.atc_country,
                atc_grouping: pageViewData.atc_grouping,
                atc_keywords: pageViewData.atc_keywords,
                atc_label: pageViewData.atc_label,
                atc_source: pageViewData.atc_source,
                atc_content: pageViewData.atc_content,
                atc_term: pageViewData.atc_term,
                atc_remarketing: pageViewData.atc_remarketing,
            },
        };
        if (
            existingTrafficParameters === null ||
            existingTrafficParameters.session_id !== trafficParameters.session_id ||
            ((trafficParameters.attribution.utm_source !== null ||
                trafficParameters.attribution.atc_source !== null) &&
                !_isEqual(trafficParameters.attribution, existingTrafficParameters.attribution))
        ) {
            storage.set("TrafficParameters", trafficParameters);
        }
    }

    /**
     * Retrieves Traffic Parameters from local storage.
     * We check to see if the session ID has changed to regenerate the
     * data if the session has expired. If there is nothing in local storage,
     * then either we are in hypernova, or pageView() hasn't been called yet. In
     * either case, we don't want to attempt to generate the data at this stage,
     * so we return an empty object.
     * @returns {Object}
     */
    _getOrResetTrafficParameters() {
        let trafficParameters = storage.get("TrafficParameters");
        if (
            !trafficParameters ||
            !trafficParameters.session_id ||
            !this.options ||
            !this.options.sessionId
        ) {
            return {};
        } else if (
            "session_id" in trafficParameters &&
            trafficParameters.session_id === this.getSessionId()
        ) {
            return trafficParameters;
        } else {
            let data = this._getPageViewData();
            this._saveTrafficParameters(data);
            return storage.get("TrafficParameters");
        }
    }

    /**
     * Retrieves UTM/ATC attribution data from local storage.
     * @returns {Object}
     */
    getTrafficAttribution() {
        let trafficParameters = this._getOrResetTrafficParameters();
        if (trafficParameters && "attribution" in trafficParameters) {
            return trafficParameters.attribution;
        }
        return {};
    }

    /**
     * Determines if session channel is PAID
     * @returns {boolean}
     */
    isPaidChannel() {
        let trafficParameters = this._getOrResetTrafficParameters();
        if (trafficParameters && "attribution" in trafficParameters) {
            return (
                trafficParameters.attribution.utm_medium === "cpc" ||
                trafficParameters.attribution.atc_medium === "cpc"
            );
        }
        return false;
    }

    /*
     * Send an payload with metadata attached
     *
     * @param {String} name - payload name
     * @param {Object} payload - payload value
     */
    trackWithMetaData(name, payload) {
        return this._queueData({
            type: name,
            data: this._addMetaData(payload),
        });
    }

    /**
     * Track a social sharing event.
     * @param {string} network The sharing network, i.e 'facebook'
     * @param {string} action What the user did, i.e 'like'
     * @param {string} target What was shared, this should be a url
     *                        but can just be text if appropriate.
     * @returns {Promise}
     */
    social(network, action, target) {
        return this._queueData({
            type: "social",
            data: this._addMetaData({
                network: network,
                action: action,
                target: target,
            }),
        });
    }

    /**
     * Tracks a search term.
     * @param {String} term
     */
    searchTerm(term) {
        return this._queueData({
            type: "search_term",
            data: term,
        });
    }

    /**
     * Returns the Lyst analytics session id.
     * @returns {string}
     */
    getSessionId() {
        return this.options.sessionId;
    }

    /**
     * Returns the Lyst analytics device id.
     * @returns {string}
     */
    getDeviceUid() {
        return this.options.deviceId;
    }

    /**
     * Returns true if this user is a returning visitor.
     * @returns {boolean}
     */
    isReturningUser() {
        return this.options.isReturning;
    }

    /**
     * Returns the number of page views in the current session.
     * Always checks cookie
     * @returns {number}
     */
    getPageViewsCount() {
        const cookieData = this.analyticsCookie.getCookieData();
        const cookieCount = cookieData.pageViewsCount;
        this.options.pageViewCount = Math.max(
            cookieCount ? cookieCount : 1,
            this.options.pageViewCount
        );
        return this.options.pageViewCount;
    }

    /**
     * Returns a the current pageView Id
     * @returns {String}
     */
    getPageViewId() {
        return this.options.pageViewId;
    }

    /**
     * Returns a new pageViewId value
     */
    getNewPageViewId() {
        const newPageViewId = uuid.uuid4();
        return newPageViewId;
    }

    /**
     * Returns the hashed email of a user.
     * @returns {string}
     */
    getSnapchatHash() {
        return this.options.snapchatHash;
    }

    // -------------------------------------------------------------------------

    /**
     * These methods should only be called in from one place:
     *
     *     `utils/history`
     *
     * Responsible for maintaining state of `pageViewId` values
     * consistent with the browser history
     */

    /**
     * Generates and sets a new `pageViewId` value
     */
    generatePageViewId() {
        this.setPageViewId(this.getNewPageViewId());
    }

    /**
     * Sets the current value of `pageViewId`
     */
    setPageViewId(pageViewId) {
        if (this.options.pageViewId !== pageViewId) {
            this.options.pageViewId = pageViewId;
            this.trigger("pageViewIdChanged");
        }
    }

    // -------------------------------------------------------------------------

    /**
     * Returns a Promise that will be resolved with
     * the Google Analytics client id.
     * @returns {Promise}
     */
    getGAClientID() {
        return googleAnalyticsTransport.getClientId();
    }

    /**
     * Returns an object containing information about the referring page view.
     * @returns {object}
     * @private
     */
    getReferrer() {
        const referrerMap = storage.get("referrerMap", {});

        return (
            referrerMap[this.referrer] || {
                href: this.referrer,
                pageViewId: null,
            }
        );
    }

    /**
     * Adds an entry to the referrer map.
     * @param {string} href
     * @param {string} pageViewId
     */
    setReferrer(href, pageViewId) {
        this.referrer = href;

        const referrerMap = storage.get("referrerMap", {});
        referrerMap[href] = { href, pageViewId };

        storage.set("referrerMap", referrerMap);
    }

    getInitialPageType() {
        return storage.get("initialPageType", null, true);
    }
    /**
     * Sets an initial pagetype into session storage, if one isn't already present.
     * @param {string} pageType
     */
    _initInitialPageType(pageType) {
        const initialPageType = this.getInitialPageType();
        if (!initialPageType) {
            this.setInitialPageType(pageType);
        }
    }

    setInitialPageType(pageType) {
        storage.set("initialPageType", pageType, true);
    }

    getInitialSubPageType() {
        return storage.get("initialSubPageType", null, true);
    }
    /**
     * Sets an initial pagetype into session storage, if one isn't already present.
     * @param {string} pageType
     */
    _initInitialSubPageType(pageType) {
        const initialPageType = this.getInitialSubPageType();
        if (!initialPageType) {
            this.setInitialSubPageType(pageType);
        }
    }

    setInitialSubPageType(pageType) {
        storage.set("initialSubPageType", pageType, true);
    }

    /**
     * The analytics cookie needs to be updated to reflect the current page count,
     * so that this information can get to the back end.
     */
    updatePageViewInAnalyticsCookie() {
        this.analyticsCookie.updatePageViewInAnalyticsCookie(this.options.pageViewCount);
    }

    /**
     * Creates a new promise, adds it to pendingPromiseResolvers,
     * schedules a send, and returns the promise.
     * @returns {Promise}
     * @private
     */
    _addPromiseAndScheduleSend() {
        return new Promise((resolve) => {
            this.pendingPromiseResolvers.push(resolve);

            // only schedule a send if the first page view has been tracked
            if (this.options.pageViewCount > 0) {
                this._scheduleSend();
            }
        });
    }

    /**
     * ORG-3513: Check if we are in the hash URL experiment case
     * more info: https://confluence.lystit.com/display/OA/FY23+Q2+-+Hash+URL+Crawl+Optimisation
     * ORG-3619: More details in https://confluence.lystit.com/pages/viewpage.action?pageId=80321368
     */
    _isHashURL() {
        const pageType = environment.get("pageType");

        if (pageType !== "product_overlay") {
            return false;
        }

        // If we don't have hash then use the current URL
        if (!globals.window.location.hash) {
            return false;
        }

        return true;
    }

    /**
     * ORG-3607: Transfer search params
     * to send to analytics
     * more info: https://confluence.lystit.com/display/OA/FY23+Q2+-+Hash+URL+Crawl+Optimisation
     */
    _getSearchParams() {
        const searchParams = new URLSearchParams(globals.window.location.search);

        searchParams.delete("origin_feed");
        searchParams.delete("originFeed");

        if (!this._isHashURL() || !searchParams.get("show_express_checkout")) {
            return searchParams;
        }

        // We need to transfer specific search params only
        // when we are opening hash URL overlay
        // from EC PDP
        const newSearchParams = new URLSearchParams();
        const allowedSearchParams = ["paid_session_id", "reason"];

        for (const [key, value] of searchParams.entries()) {
            if (allowedSearchParams.includes(key)) {
                newSearchParams.set(key, value);
            }
        }

        return newSearchParams;
    }

    /**
     * ORG-3513: Get the correct URL
     * to send to analytics
     * more info: https://confluence.lystit.com/display/OA/FY23+Q2+-+Hash+URL+Crawl+Optimisation
     */
    _getURL() {
        const productURL = environment.get("productURL");

        if (!this._isHashURL() || !productURL) {
            return globals.window.location;
        }

        let searchParams = this._getSearchParams();

        const url = new URL(
            `${productURL}?${searchParams.toString()}`,
            globals.window.location.href
        );

        return url;
    }

    /**
     * Generates a page view object.
     * @returns {Object}
     * @private
     */
    _getPageViewData() {
        const referrer = this.getReferrer();
        const params = this._getSearchParams();
        const url = this._getURL();
        const pageType = environment.get("pageType", "");
        const pageSubType = environment.get("pageSubType", "");

        if (!pageType) {
            throw new Error("No pageType found in the environment");
        }

        return this._addMetaData({
            // metadata
            _version: "0.13.0",
            _type: "page_views",
            _subtype: pageSubType
                ? `${pageType.toLowerCase()}.${pageSubType.toLowerCase()}`
                : pageType.toLowerCase(),

            // events
            category: pageType.toLowerCase(),
            action: pageSubType.toLowerCase(),

            // user data
            returning_user: this.isReturningUser(),
            country: environment.get("country", ""),
            language: environment.get("language", ""),
            domain: this.analyticsCookie.cookieDomain,
            signed_in: environment.get("userSignedInStatus", "no"),

            // product data
            product_id: environment.get("analyticsProduct.id", null),
            product_has_single_gallery: environment.get("productHasSingleGallery", null),

            // page data
            page_type: pageType,
            page_sub_type: pageSubType,
            initial_page_type: this.getInitialPageType(),
            initial_page_sub_type: this.getInitialSubPageType(),

            // URL related fields
            // ORG-3513: Make sure that we send the product URL
            // instead of the hash URL we have pushed to the history
            // see https://confluence.lystit.com/display/OA/FY23+Q2+-+Hash+URL+Crawl+Optimisation
            url: url.href,
            url_host: url.host,
            url_query: url.search.slice(1),
            url_path: url.pathname,

            referrer: referrer.href,
            referrer_page_view_id: referrer.pageViewId,
            causative_user_action: getLastParamOrNull(params, "reason"),

            // search terms
            search_term: getLastParamOrNull(params, "term"),
            autosuggest_term: getLastParamOrNull(params, "q"),

            // variables needed for GA
            gaVariables: environment.get("gaVariables"),

            // device data
            user_agent: navigator.userAgent,

            // its save to use the actual `window` here
            screen_width: window.screen.width,
            screen_height: window.screen.height,
            window_width: browser.getWindowWidth(),
            window_height: browser.getWindowHeight(),
            device_pixel_ratio: window.devicePixelRatio || 1,

            // Paid traffic data
            utm_medium: getLastParamOrNull(params, "utm_medium"),
            utm_campaign: getLastParamOrNull(params, "utm_campaign"),
            utm_source: getLastParamOrNull(params, "utm_source"),
            utm_content: getLastParamOrNull(params, "utm_content"),
            utm_term: getLastParamOrNull(params, "utm_term"),
            utm_country: getLastParamOrNull(params, "utm_country"),
            utm_grouping: getLastParamOrNull(params, "utm_grouping"),
            utm_keywords: getLastParamOrNull(params, "utm_keywords"),

            // Paid traffic data
            atc_medium: getLastParamOrNull(params, "atc_medium"),
            atc_campaign: getLastParamOrNull(params, "atc_campaign"),
            atc_country: getLastParamOrNull(params, "atc_country"),
            atc_grouping: getLastParamOrNull(params, "atc_grouping"),
            atc_keywords: getLastParamOrNull(params, "atc_keywords"),
            atc_label: getLastParamOrNull(params, "atc_label"),
            atc_source: getLastParamOrNull(params, "atc_source"),
            atc_content: getLastParamOrNull(params, "atc_content"),
            atc_term: getLastParamOrNull(params, "atc_term"),
            atc_remarketing: getLastParamOrNull(params, "atc_remarketing"),

            // Paid SEM Ids
            sem_id: getLastParamOrNull(params, "sem_id"),

            // PAID-1986 google clicks id
            gclid: getLastParamOrNull(params, "gclid"),

            // PAID-2363 facebook click id
            fbclid: getLastParamOrNull(params, "fbclid"),

            // PAID-2371 bing click id
            msclkid: getLastParamOrNull(params, "msclkid"),

            // PAID-3392 tiktok click id
            ttclid: getLastParamOrNull(params, "ttclid"),

            // PAID-2303 paid session id
            paid_session_id: getLastParamOrNull(params, "paid_session_id"),

            // life cycle data
            life_cycle_stage: environment.get("lifeCycleStage"),

            heimdall_flags: environment.get("heimdallFlags"),

            // feature support
            supports_local_storage: browser.supportsLocalStorage,
            supports_touch: browser.hasTouch,
        });
    }

    /**
     * Sends the current `pendingData` to essential transports and all valid third
     * party transports, if cookie consent has been confirmed. If cookie consent has
     * not been confirmed then the data is added to the `pendingThirdPartyData` queue
     * so it can be sent if and when consent is confirmed.
     * When all defined transports have indicated they have sent their
     * data then all Promises in `pendingPromiseResolvers` are resolved.
     * @private
     */
    _send() {
        if (globals.document.readyState !== "complete") {
            globals.window.addEventListener("load", this._scheduleSend.bind(this), false);
            return;
        }

        // create local copies of both data and promises array so they
        // can continue to be used without affecting this functions scope
        let data = _clone(this.pendingData);
        let promises = _clone(this.pendingPromiseResolvers);

        // create a wrapper promise for when all transports are done
        const allDataSentPromises = [];
        function sendData(transports) {
            return _map(transports, (transport) => {
                allDataSentPromises.push(transport.send(data));
            });
        }

        sendData(this.essentialTransports);

        if (this.cookieConsent.consentConfirmed) {
            sendData(this._getThirdPartyTransports());
        } else {
            // add to third party queue
            this.pendingThirdPartyData = this.pendingThirdPartyData.concat(data);
        }

        const allDataSent = Promise.all(allDataSentPromises);

        // reset global data and promises array
        this.pendingData.length = 0;
        this.pendingPromiseResolvers.length = 0;

        // setup a timeout in case things go wrong
        let timeout;

        function resolvePromises() {
            clearTimeout(timeout);
            promises.forEach((resolve) => resolve());
        }

        timeout = setTimeout(resolvePromises, 5000);

        // when all the data is sent resolve the promises
        allDataSent.then(resolvePromises).catch(resolvePromises);
    }

    /**
     * Sends all `pendingThirdPartyData` to valid third party transports.
     * @private
     */
    _sendThirdPartyData() {
        if (this.pendingThirdPartyData.length === 0) {
            return;
        }

        // create local copies of both data and promises array so they
        // can continue to be used without affecting this functions scope
        let clonedData = _clone(this.pendingThirdPartyData);

        const allDataSent = Promise.all(
            _map(this._getThirdPartyTransports(), (transport) => {
                return transport.send(clonedData);
            })
        );

        // setup a timeout in case things go wrong
        let timeout;

        const clearQueue = () => {
            clearTimeout(timeout);

            // reset the queue once all data is sent
            this.pendingThirdPartyData.length = 0;
        };

        timeout = setTimeout(clearQueue, 5000);

        // when all the data is sent resolve the promises
        allDataSent.then(clearQueue).catch(clearQueue);
    }

    /**
     * Schedules current analytics data to be sent when the current execution stack is complete.
     * This allows multiple analytics events being generated from different modules to be batched together.
     * If a call to `_send()` has already been scheduled but not yet called it is cancelled and
     * a new call scheduled.
     *
     * The use of setTimeout with a delay value of 0ms causes the function to be
     * executed when the current execution stack completes. Using 0ms is indicating intention
     * for 'as-soon-as-possible' behavior, all modern browsers will treat this as the minimum
     * timeout value (4ms).
     *
     * https://developer.mozilla.org/en-US/docs/Web/API/window.setTimeout#Minimum_delay_and_timeout_nesting
     * @private
     */
    _scheduleSend() {
        clearTimeout(this.sendTimeout);
        this.sendTimeout = setTimeout(this._send, 0);
    }

    /**
     * Enriches the passed object with common meta data that all
     * objects being added to `pendingData` should contain.
     * @param {Object} obj The object to enrich with meta data
     * @returns {Object}
     */
    _addMetaData(obj) {
        const now = this._getUTCTime();
        return _extend(obj, {
            _dt: now,
            _uuid: uuid.uuid4(),
            _source: "lyst_web.frontend.analytics",
            _status: "v",
            session_id: this.getSessionId(),
            device_id: this.getDeviceUid(),
            user_id: environment.get("userId", ""),
            page_view_count: this.getPageViewsCount(),
            pageview_id: this.getPageViewId(),
            event_timestamp: now,
            release: environment.get("release"),
            release_sha: environment.get("release_sha"),
        });
    }

    /**
     * Returns the current UTC time string in the format "YYYY-MM-DDTHH:mm:ss.SSSZ"
     * Value is based off the reported time on page + given server time
     * @param [d] Used for tests
     * @returns {string}
     * @private
     */
    _getUTCTime(d) {
        let now = d || new Date();
        let browserTimeStart = this._browserTimeStart;

        // Server time is seconds since epoch
        let serverTimeStart = parseInt(environment.get("timestamp_ms"), 10);

        // Browser time delta is milliseconds since epoch
        let browserDelta = now - browserTimeStart;

        // Milliseconds since epoch using servertime and the browser delta
        let timestampMilliseconds = serverTimeStart + browserDelta;
        let timestamp = new Date(timestampMilliseconds);

        return timestamp.toISOString();
    }

    analyticsCookie = null;

    /**
     * Reads and parses the analytics cookie.
     * @private
     */
    _setInitialState() {
        this.analyticsCookie = new AnalyticsCookie(COOKIE_VERSION);
        let cookieData = this.analyticsCookie.getCookieData();

        this.analyticsCookie.deleteOldCookies();

        if (!cookieData.sessionId) {
            cookieData = this.analyticsCookie.createCookie(uuid.uuid4(), uuid.uuid4());
        }

        this.options = {
            sessionId: cookieData.sessionId,
            pageViewCount: cookieData.pageViewsCount,
            isReturning: cookieData.isReturning,
            deviceId: cookieData.deviceUid,
            pageViewId: environment.get("pageViewId"),
            snapchatHash: environment.get("snapchatHash", false),
        };

        if (!this.options.pageViewCount) {
            // If there is no count in the cookie, initialise as 1
            this.options.pageViewCount = 1;
            this.updatePageViewInAnalyticsCookie();
        } else {
            // Otherwise, allow incrementing
            this._allowPageViewsCountIncrement = true;
        }
    }

    /**
     * Calls `init()` on any transports that define that method.
     * @private
     */
    _initTransports() {
        this.transports = this._getTransports();

        this.transports.forEach((transport) => {
            if (_isFunction(transport.init)) {
                transport.init(this);
            }
        });
    }
}

// create an instance
let analytics = new Analytics();

// expose the class for testing
analytics.Analytics = Analytics;

// return the instance as the modules export
export default analytics;
