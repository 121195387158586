import React, { useState } from "react";
import { Tooltip } from "web/react/emo/tooltip";
import { useFeedContext } from "web/react/pages/feed/feed.context";
import { FeedPage } from "web/react/pages/feed/utils";
import { useInStockProductPageContext } from "web/react/pages/product/in-stock/in-stock-product-page/in-stock-product-page.context";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import environment from "web/script/modules/environment";
import storage from "web/script/utils/storage";
import { PageSource } from "./save-for-later-button";

const TOOLTIP_LIMIT = 3;

interface TooltipControlProps {
    pageSource: PageSource;
    productPosition: number | undefined;
    linkId: number | undefined;
    isSaved?: boolean;
    children: React.ReactNode;
}

export function TooltipControl({
    pageSource,
    productPosition,
    linkId,
    isSaved,
    children,
}: TooltipControlProps): JSX.Element {
    const isWishlistTooltipExperiment = !!environment.getFeature("mg_wishlist_tooltip_experiment");
    const { feedTooltipCount } = useFeedContext();
    const { pdpTooltipCount, isOverlay, parentRef } = useInStockProductPageContext();

    let tooltipCount =
        pageSource === "pdp" && pdpTooltipCount
            ? pdpTooltipCount
            : pageSource === "feed" && feedTooltipCount
            ? feedTooltipCount
            : 0;

    function shouldRenderTooltip(): boolean {
        if (
            !isWishlistTooltipExperiment ||
            tooltipCount >= TOOLTIP_LIMIT ||
            pageSource === "oos" ||
            isSaved
        ) {
            return false;
        }

        if (pageSource === "feed") {
            if (environment.get("feedType") === FeedPage.WISH_LIST) return false;

            if (productPosition !== 0) return false;
        }

        return true;
    }

    const tooltipPlacement = pageSource === "pdp" ? "top-end" : "top";
    const [containerTop, setContainerTop] = useState<number | null>(null);
    const [containerLeft, setContainerLeft] = useState<number | null>(null);

    function onShow(): void {
        if (isOverlay) {
            const lazyModal = document.querySelector("#lazy-modal");
            if (lazyModal) {
                setContainerTop(lazyModal.getBoundingClientRect().top - lazyModal.scrollTop);
                setContainerLeft(lazyModal.getBoundingClientRect().left - lazyModal.scrollLeft);
            }
        }

        storage.set(`${pageSource}TooltipCount`, tooltipCount + 1);

        analytics.event("wishlist_tooltip", "viewed", "", true, {
            buyOptionId: linkId,
            pageType: pageSource,
        });
    }

    return shouldRenderTooltip() ? (
        <Tooltip
            text={gettext("tooltip.wishlist")}
            placement={tooltipPlacement}
            delay={200}
            duration={5000}
            onShow={onShow}
            parentRef={parentRef}
            containerTop={containerTop}
            containerLeft={containerLeft}
        >
            {children}
        </Tooltip>
    ) : (
        <>{children}</>
    );
}
