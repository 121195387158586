import clsx from "clsx";
import React, { useRef } from "react";
import { CustomerCaptureOverlay } from "web/react/components/customer-capture-overlay/customer-capture-overlay";
import { ProductOverlay } from "web/react/components/product-overlay";
import { container } from "web/react/emo/utilities.css";
import { View } from "web/react/emo/view";
import { ProductLayoutSerializer, ProductPageAreaSerializer } from "web/types/serializers";
import { Breadcrumbs } from "./breadcrumbs/breadcrumbs";
import { Footer } from "./footer/footer";
import {
    InStockProductPageContextProvider,
    ProductPurchaseType,
} from "./in-stock-product-page.context";
import { wrapper } from "./in-stock-product-page.css";
import { ProductArea } from "./product-area/product-area";
import { ProductPriceComparison } from "./product-price-comparison/product-price-comparison";
import { RelatedArea } from "./suggested-product/suggested-product";

export interface InStockAffiliateProductPageProps {
    productArea: ProductPageAreaSerializer;
    productLayout: ProductLayoutSerializer;
    helpCentreURLs?: string[];
    isOverlay?: boolean;
    parentRef?: React.RefObject<HTMLElement>;
}

export function InStockProductPage({
    productArea,
    productLayout,
    helpCentreURLs,
    isOverlay = false,
    parentRef,
}: InStockAffiliateProductPageProps): JSX.Element {
    const overlayRef = useRef(null);

    return (
        <InStockProductPageContextProvider
            initialData={{ productArea, productLayout, isOverlay, parentRef }}
        >
            {!isOverlay && <CustomerCaptureOverlay />}
            {!isOverlay && (
                <div ref={overlayRef}>
                    <ProductOverlay parentRef={overlayRef} />
                </div>
            )}
            <View className={clsx(container, wrapper)}>
                {productArea && <ProductArea />}
                {productArea.product_purchase_type !== ProductPurchaseType.EXPRESS_CHECKOUT && (
                    <ProductPriceComparison />
                )}
                <RelatedArea productLayout={productLayout} />
                <Breadcrumbs productLayout={productLayout} />
            </View>
            {helpCentreURLs && (
                <Footer productLayout={productLayout} helpCentreURLs={helpCentreURLs} />
            )}
        </InStockProductPageContextProvider>
    );
}
