import clsx from "clsx";
import _escape from "lodash/escape";
import React from "react";
import { ProductCardLink } from "web/react/components/product-card/react/product-card-link";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { HStack } from "web/react/emo/h-stack";
import { Text } from "web/react/emo/text";
import { gettext } from "web/script/modules/django-i18n";
import { getHtmlProps } from "web/script/modules/html-tag-props";
import * as styles from "./retailer.css";

interface RetailerProps {
    retailerName: string;
    shouldRenderLink: boolean;
    feedType?: string;
    fromSpanStyle: keyof typeof styles.from;
    showLinkIcon?: boolean;
    enableBoldRetailerName?: boolean;
    handleRetailerClick: () => void;
}

interface RetailerLinkProps {
    shouldRenderLink: boolean;
    children: React.ReactNode;
    handleRetailerClick: () => void;
    feedType?: string;
}

function RetailerLink({
    shouldRenderLink,
    feedType,
    children,
    handleRetailerClick,
}: RetailerLinkProps): React.ReactNode {
    if (shouldRenderLink) {
        return (
            <ProductCardLink
                className={styles.retailerLink}
                reason={"retailer"}
                feedType={feedType}
                predicate={true}
                onClick={handleRetailerClick}
            >
                {children}
            </ProductCardLink>
        );
    }

    return children;
}

export function Retailer({
    retailerName,
    shouldRenderLink,
    feedType,
    fromSpanStyle,
    handleRetailerClick,
    showLinkIcon = false,
    enableBoldRetailerName = true,
}: RetailerProps): JSX.Element {
    const fromSpanProps = {
        class: styles.from[fromSpanStyle],
    };

    const retailerNameSpanProps = {
        class: clsx(styles.from[fromSpanStyle], { [styles.retailerName]: enableBoldRetailerName }),
    };

    const retailerLabelSafeHtml = {
        __html: gettext("in_stock_product.from_retailer.in_spans", {
            from_span_props: getHtmlProps(fromSpanProps),
            retailer_name_span_props: getHtmlProps(retailerNameSpanProps),
            retailer_name: _escape(retailerName),
        }),
    };

    return (
        <div className={styles.retailerContainer}>
            <RetailerLink
                shouldRenderLink={shouldRenderLink}
                feedType={feedType}
                handleRetailerClick={handleRetailerClick}
            >
                <div>
                    {showLinkIcon && (
                        <SVGIcon
                            className={styles.hyperlinkIcon}
                            data-testid="icon"
                            name="hyperlink"
                        />
                    )}
                    <span
                        data-testid="retailer-name"
                        dangerouslySetInnerHTML={retailerLabelSafeHtml}
                    />
                </div>
            </RetailerLink>
        </div>
    );
}

export function SponsoredByRetailer({
    retailerName,
    showLinkIcon = false,
}: {
    retailerName: string;
    showLinkIcon?: boolean;
}): JSX.Element {
    return (
        <HStack spacing="xxs">
            {showLinkIcon && <SVGIcon data-testid="icon" name="hyperlink" />}
            <Text as="span" textStyle="body-3-small" lineLimit={2}>
                {
                    // Sponsored by {retailerName}
                    gettext("in_stock_product.sponsored_by_retailer", {
                        retailer_name: retailerName,
                    })
                }
            </Text>
        </HStack>
    );
}
