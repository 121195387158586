import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import { useRothko } from "web/react/hooks/use-rothko/use-rothko";
import { ReduxStoreState } from "web/redux/store";
import analytics from "web/script/analytics/analytics";
import globals from "web/script/modules/globals";
import { getSearchPageURL } from "web/script/routing/routes";
import navigate from "web/script/utils/navigate";
import {
    AutoSuggestSerializer,
    ProfileSuggestion,
    QuerySuggestion,
    RecentSearchSerializer,
} from "web/types/serializers";

const AUTO_SUGGEST_API_ENDPOINT = "modules/search_input/auto_suggest";
const EVENT_CATEGORY = "top_nav";

interface AutoSuggestContextProps {
    searchInputRef: React.RefObject<HTMLInputElement> | null;
    isSearchInputFocused: boolean;
    activeSuggestionIndex: number | null;
    query: string;
    hasAnySuggestions: boolean;
    recentSearches: RecentSearchSerializer[];
    querySuggestions: QuerySuggestion[];
    profileSuggestions: ProfileSuggestion[];
    updateAutoSuggestion: (any) => void;
    makeSuggestionActive: (index: number | null, replace?: boolean) => void;
    onKeyDown: (event: React.SyntheticEvent) => void;
    onSubmit: (event: React.SyntheticEvent) => void;
    onInput: (event: React.SyntheticEvent) => void;
    onSuggestionClick: (event: React.MouseEvent<HTMLElement>) => void;
    setSearchInputFocused: React.Dispatch<React.SetStateAction<boolean>>;
    setRecentSearches: React.Dispatch<React.SetStateAction<RecentSearchSerializer[]>>;
    isVisible: boolean;
    recentSearchesCleared: boolean;
    setRecentSearchesCleared: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AutoSuggestContext = createContext<AutoSuggestContextProps>({
    searchInputRef: null,
    isSearchInputFocused: false,
    activeSuggestionIndex: null,
    query: "",
    hasAnySuggestions: false,
    recentSearches: [],
    querySuggestions: [],
    profileSuggestions: [],
    updateAutoSuggestion: () => {},
    makeSuggestionActive: () => {},
    onKeyDown: () => {},
    onSubmit: () => {},
    onInput: () => {},
    onSuggestionClick: () => {},
    setSearchInputFocused: () => {},
    setRecentSearches: () => {},
    isVisible: false,
    recentSearchesCleared: false,
    setRecentSearchesCleared: () => {},
});

function extractSelectedGender(state: ReduxStoreState): string {
    return state.mainNavigationReducer.selectedGender;
}

export function getSearchLimits(isDesktopViewport: boolean): {
    recentSearchesLimit: number | undefined;
    profileSuggestionsLimit: number | undefined;
    querySuggestionsLimit: number | undefined;
} {
    const recentSearchesLimit = isDesktopViewport ? undefined : 6;
    const profileSuggestionsLimit = isDesktopViewport ? undefined : 3;
    const querySuggestionsLimit = isDesktopViewport ? undefined : 3;

    return { recentSearchesLimit, profileSuggestionsLimit, querySuggestionsLimit };
}

export function AutoSuggestProvider({ children }: ReactComponentProps): React.ReactElement {
    const searchInputRef = useRef<HTMLInputElement>(null);
    const [recentSearches, setRecentSearches] = useState<RecentSearchSerializer[]>([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState<number | null>(null);
    const [isSearchInputFocused, setSearchInputFocused] = useState<boolean>(false);
    const [activeQuery, setActiveQuery] = useState<string | null>(null);
    const [useActiveQuery, setUseActiveQuery] = useState<boolean>(false);
    const [hasAnySuggestions, setHasAnySuggestions] = useState<boolean>(false);
    const [querySuggestions, setQuerySuggestions] = useState<QuerySuggestion[]>([]);
    const [profileSuggestions, setProfileSuggestions] = useState<ProfileSuggestion[]>([]);
    const [suggestions, setSuggestions] = useState<
        (RecentSearchSerializer | ProfileSuggestion | QuerySuggestion)[]
    >([]);
    const [query, setQuery] = useState<string>("");
    const gender = useSelector(extractSelectedGender);
    const returnedQuery = useActiveQuery ? activeQuery || "" : query;
    const isVisible =
        (hasAnySuggestions && query !== "" && isSearchInputFocused) ||
        (recentSearches.length > 0 && query === "" && isSearchInputFocused);
    const [recentSearchesCleared, setRecentSearchesCleared] = useState<boolean>(false);
    const { isDesktopViewport } = useDomViewport();
    const { recentSearchesLimit, profileSuggestionsLimit, querySuggestionsLimit } =
        getSearchLimits(isDesktopViewport);

    const { data, run } = useRothko<AutoSuggestSerializer>(
        AUTO_SUGGEST_API_ENDPOINT,
        {
            query,
            gender,
        },
        {
            enabled: false,
        }
    );

    useEffect(() => {
        /**
         * If query is falsey then the suggestions
         * list is populated with the users previous search terms. Otherwise the components data is
         * fetched using Rothko.
         */
        if (!isSearchInputFocused) {
            return;
        }

        setProfileSuggestions([]);
        setQuerySuggestions([]);
        setHasAnySuggestions(false);
        setSuggestions(recentSearches.slice(0, recentSearchesLimit));

        if (query) {
            run();
        }
    }, [gender, query, run, isSearchInputFocused, recentSearches, recentSearchesLimit]);

    useEffect(() => {
        if (data) {
            const profileSuggestionsData = data.profile_suggestions || [];
            const querySuggestionsData = data.query_suggestions || [];

            const profileSuggestions = profileSuggestionsData.slice(0, profileSuggestionsLimit);
            const querySuggestions = querySuggestionsData.slice(0, querySuggestionsLimit);

            setProfileSuggestions(profileSuggestions);
            setQuerySuggestions(querySuggestions);
            setSuggestions([...profileSuggestions, ...querySuggestions]);
            setHasAnySuggestions(!!(profileSuggestions.length > 0 || querySuggestions.length > 0));
        }
    }, [data, query, profileSuggestionsLimit, querySuggestionsLimit]);

    function makeSuggestionActive(index: number | null, replaceQuery = false): void {
        setActiveSuggestionIndex(index !== activeSuggestionIndex ? index : null);

        if (replaceQuery && query) {
            const newQuery =
                index !== null
                    ? index >= suggestions.length
                        ? query
                        : Object.prototype.hasOwnProperty.call(suggestions[index], "filters") // Check if recent search
                        ? (suggestions[index] as RecentSearchSerializer).query
                        : Object.prototype.hasOwnProperty.call(suggestions[index], "query") // Check if query suggestion
                        ? (suggestions[index] as QuerySuggestion).query
                        : (suggestions[index] as ProfileSuggestion).name
                    : query;

            setActiveQuery(newQuery);
            setUseActiveQuery(true);
        }
    }

    function navigateSuggestions(direction: "next" | "prev", replaceQuery = false): void {
        const step = direction === "next" ? 1 : -1;
        const suggestionsLength = querySuggestions.length + profileSuggestions.length;

        if (activeSuggestionIndex === null) {
            makeSuggestionActive(step === 1 ? 0 : suggestionsLength + step, replaceQuery);
            return;
        }

        if (
            (activeSuggestionIndex === 0 && step === -1) ||
            (activeSuggestionIndex === suggestionsLength - 1 && step === 1)
        ) {
            makeSuggestionActive(null, replaceQuery);
            return;
        }

        makeSuggestionActive(activeSuggestionIndex + step, replaceQuery);
    }

    function updateAutoSuggestion({ gender, query }): void {
        if (gender !== undefined) {
            updateAutoSuggestion(gender);
        }

        if (query !== undefined) {
            setQuery(query);
        }
    }

    function onKeyDown(event): void {
        switch (event.key) {
            // up key
            case "ArrowUp":
                event.preventDefault();
                navigateSuggestions("prev", true);
                break;

            // down key
            case "ArrowDown":
                event.preventDefault();
                navigateSuggestions("next", true);
                break;

            // tab key
            case "Tab":
                if (query.length) {
                    event.preventDefault();
                    navigateSuggestions(event.shiftKey === true ? "prev" : "next", true);
                }
                break;
        }
    }

    function onInput(event): void {
        if (query === "" && event.type === "input") {
            analytics.event(EVENT_CATEGORY, "search_input", "search_bar");
        }
        setUseActiveQuery(false);
        setQuery(event.target.value);
    }

    function submitSearch(suggestion: QuerySuggestion | null): void {
        let target = new URL(getSearchPageURL(), globals.window.location.href);
        const searchQuery = suggestion
            ? suggestion.query
            : useActiveQuery
            ? activeQuery?.trim() || ""
            : query.trim();

        target.searchParams.set("q", searchQuery);
        target.searchParams.set("term", searchQuery);
        gender && target.searchParams.set("gender", gender);
        navigate(target.href);
    }
    function onSubmit(event): void {
        event.preventDefault();

        const activeSuggestion =
            activeSuggestionIndex !== null ? suggestions[activeSuggestionIndex] : undefined;

        if (activeSuggestion && activeSuggestion.type === "profile") {
            analytics.event(EVENT_CATEGORY, "clicked_result", "top_result");
            navigate((activeSuggestion as ProfileSuggestion).path);
        } else if (activeSuggestion?.type === "query") {
            analytics.event(EVENT_CATEGORY, "clicked_result", "other_suggestions");
            submitSearch(activeSuggestion as QuerySuggestion);
        } else if (activeSuggestion) {
            submitSearch(activeSuggestion as QuerySuggestion);
            analytics.event(EVENT_CATEGORY, "clicked_result", "recent_searches");
        } else {
            analytics.event(EVENT_CATEGORY, "search_submit", "search_bar");
            submitSearch(null);
        }
    }

    function onSuggestionClick(event: React.MouseEvent<HTMLElement>): void {
        event.preventDefault();
        const suggestionIndex = parseInt(event.currentTarget.dataset.suggestionIndex as string, 0);
        const activeSuggestion = suggestions[suggestionIndex];
        const { suggestionType } = event.currentTarget.dataset;
        makeSuggestionActive(suggestionIndex, true);
        if (suggestionType === "recent") {
            analytics.event(EVENT_CATEGORY, "clicked_result", "recent_searches");
            navigate((activeSuggestion as RecentSearchSerializer).url);
        } else if (activeSuggestion.type === "query") {
            analytics.event(EVENT_CATEGORY, "clicked_result", "other_suggestions");
            submitSearch(activeSuggestion as QuerySuggestion);
        } else if (["category", "profile"].includes(activeSuggestion.type || "")) {
            analytics.event(EVENT_CATEGORY, "clicked_result", "top_result");
            navigate((activeSuggestion as ProfileSuggestion).path);
        }
    }

    return (
        <AutoSuggestContext.Provider
            value={{
                searchInputRef,
                isSearchInputFocused,
                activeSuggestionIndex,
                query: returnedQuery,
                makeSuggestionActive,
                hasAnySuggestions,
                recentSearches,
                querySuggestions,
                profileSuggestions,
                updateAutoSuggestion,
                onKeyDown,
                onInput,
                onSubmit,
                onSuggestionClick,
                setSearchInputFocused,
                setRecentSearches,
                isVisible,
                recentSearchesCleared,
                setRecentSearchesCleared,
            }}
        >
            {children}
        </AutoSuggestContext.Provider>
    );
}

export function useAutoSuggestContext(): AutoSuggestContextProps {
    return useContext(AutoSuggestContext);
}
