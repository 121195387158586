import React, { useRef, useState } from "react";
import { VStack } from "web/react/emo/v-stack";
import { useAboveFold } from "web/react/hooks/use-above-fold/use-above-fold";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import useIsomorphicLayoutEffect from "web/react/hooks/use-isomorphic-layout-effect/use-isomorphic-layout-effect";
import analytics from "web/script/analytics/analytics";
import { BaseRail } from "./base-rail";
import { RailHeader } from "./rail-header";
import { ShowMoreButton } from "./show-more-button";

const DEFAULT_WRAP_LIMIT = Symbol("defaultWrapLimit");
const DESKTOP_WRAP_LIMIT = 10;
const MOBILE_WRAP_LIMIT = 5;

export function calculateWrapLimit(
    wrapLimit: number | typeof DEFAULT_WRAP_LIMIT,
    isMobileViewport
): number {
    const wrapLimitValue =
        wrapLimit === DEFAULT_WRAP_LIMIT
            ? isMobileViewport
                ? MOBILE_WRAP_LIMIT
                : DESKTOP_WRAP_LIMIT
            : wrapLimit;

    return wrapLimitValue;
}

interface RelatedRailProps {
    analyticsLabel: string;
    children?: React.ReactNode[];
    title?: string;
    caption?: string;
    ctaUrl?: string;
    shouldWrap?: boolean;
    alwaysWrap?: boolean;
    wrapLimit?: number | typeof DEFAULT_WRAP_LIMIT;
    listItemClassName?: string;
    variant?: "primary" | "secondary";
    headerClassName?: string;
    onCtaClick?: (any?) => void;
}

export function Rail({
    title,
    caption,
    ctaUrl,
    children,
    analyticsLabel,
    listItemClassName,
    shouldWrap = false,
    alwaysWrap = false,
    wrapLimit = DEFAULT_WRAP_LIMIT,
    variant = "primary",
    headerClassName,
    onCtaClick,
}: RelatedRailProps): React.ReactElement {
    const [hasInteracted, setHasInteracted] = useState(false);
    const [showMore, setShowMore] = useState<boolean>(false);
    const [isAtStart, setIsAtStart] = useState<boolean>(false);
    const [hasReachedEnd, setHasReachedEnd] = useState<boolean>(false);
    const [allItemsVisible, setAllItemsVisible] = useState<boolean>(false);
    const [hasBeenSeen, setHasBeenSeen] = useState<boolean>(false);

    const { isMobileViewport } = useDomViewport();

    const railID = `${analyticsLabel}-rail`;
    const railRef = useRef<HTMLOListElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const { isVisible } = useAboveFold(containerRef);

    const wrapLimitValue = calculateWrapLimit(wrapLimit, isMobileViewport);

    const wrap = (isMobileViewport && shouldWrap) || alwaysWrap;

    const isShowMoreButton = wrap && (children?.length ?? 0) > wrapLimitValue;

    function isPillHidden(index: number): boolean {
        return index >= wrapLimitValue && !showMore;
    }

    function expand(): void {
        const target = railRef?.current as HTMLOListElement;

        setAllItemsVisible(target && target?.scrollWidth === target?.offsetWidth);
        setHasReachedEnd(
            target && target?.scrollLeft + target?.offsetWidth === target?.scrollWidth
        );
        setIsAtStart(target && target.scrollLeft === 0);
    }

    function scrollInteraction(): void {
        if (!hasInteracted) {
            setHasInteracted(true);
            analytics.event("rail", "scroll", analyticsLabel);
        }
        expand();
    }

    useIsomorphicLayoutEffect(() => {
        if (isVisible && !hasBeenSeen) {
            analytics.event("rail", "impression", analyticsLabel);
            setHasBeenSeen(true);
        }
    }, [isVisible]);

    const isScrollable = !allItemsVisible && !wrap;

    const isSecondaryVariant = variant === "secondary";

    return (
        <div ref={containerRef} data-testid={railID}>
            <VStack spacing={isSecondaryVariant ? "lg" : "sm"}>
                <div className={headerClassName}>
                    <RailHeader
                        variant={variant}
                        title={title}
                        caption={caption}
                        railRef={railRef}
                        scrollInteraction={scrollInteraction}
                        isAtStart={isAtStart}
                        hasReachedEnd={hasReachedEnd}
                        ctaUrl={ctaUrl}
                        onCtaClick={onCtaClick}
                        isScrollable={isScrollable}
                    />
                </div>
                <BaseRail
                    wrap={wrap}
                    onScroll={scrollInteraction}
                    railRef={railRef}
                    isPillHidden={isPillHidden}
                    listItemClassName={listItemClassName}
                    spacing={isSecondaryVariant ? "md" : "xs"}
                >
                    {children}
                </BaseRail>
                {isShowMoreButton && (
                    <ShowMoreButton
                        showMore={showMore}
                        setShowMore={setShowMore}
                        onClick={expand}
                        analyticsLabel={analyticsLabel}
                    />
                )}
            </VStack>
        </div>
    );
}
