import { assignInlineVars } from "@vanilla-extract/dynamic";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { EURO_COUNTRIES } from "web/react/constants";
import { useWindowWidth } from "web/react/hooks/use-window-width/use-window-width";
import { gettext } from "web/script/modules/django-i18n";
import environment from "web/script/modules/environment";
import * as styles from "./value-proposition-animation.css";

enum ImageSize {
    height = 4499,
    width = 871,
}

function getAnimationAsset(country: string): string {
    if (country === "AU") {
        return "url('https://cdna.lystit.com/static/n/static-img/sign-up-modal/wishlist-animation-au.jpg')";
    } else if (country === "CA") {
        return "url('https://cdna.lystit.com/static/n/static-img/sign-up-modal/wishlist-animation-ca.jpg')";
    } else if (country === "GB") {
        return "url('https://cdna.lystit.com/static/n/static-img/sign-up-modal/wishlist-animation-gb.jpg')";
    } else if (EURO_COUNTRIES.has(country)) {
        return "url('https://cdna.lystit.com/static/n/static-img/sign-up-modal/wishlist-animation-eu.jpg')";
    } else {
        return "url('https://cdna.lystit.com/static/n/static-img/sign-up-modal/wishlist-animation-us.jpg')";
    }
}

export function ValuePropositionAnimation({ className }: { className?: string }): JSX.Element {
    const [bgImageHeight, setBgImageHeight] = useState("");
    const [restartAnimation, setRestartAnimation] = useState(false);
    const divRef = useRef<HTMLDivElement>(null);
    const windowWidth = useWindowWidth();

    const country = environment.get("country");
    const animationAsset = getAnimationAsset(country);

    useEffect(() => {
        const divWith = (divRef.current && divRef.current.offsetWidth) || 0;
        const bgImageHeight = (ImageSize.height * divWith) / ImageSize.width;
        setBgImageHeight(`-${bgImageHeight}px`);
        setRestartAnimation(true);
    }, [windowWidth]);

    return (
        <div className={clsx(styles.wrapper, className)}>
            <div
                className={clsx(styles.hero, { [styles.scrollUpAnimation]: restartAnimation })}
                ref={divRef}
                style={assignInlineVars({
                    [styles.imageHeight]: bgImageHeight,
                    [styles.animationAsset]: animationAsset,
                })}
                aria-label={
                    // "Get alerts on items, Save your searches, Create a wishlist"
                    gettext("account.all_benefits.value_prop.label")
                }
            ></div>
        </div>
    );
}
