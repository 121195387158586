import clsx from "clsx";
import React from "react";
import SaveForLaterButton from "web/react/components/save-for-later-button/save-for-later-button";
import { ProductStatus } from "web/redux/ducks/customer-capture-overlay";
import userProfiler from "web/script/modules/userprofiler";
import { canUseMembership } from "web/script/modules/utils";
import { useProductCardContext } from "./product-card";
import styles from "./product-card-save-for-later.module.css";

interface ProductCardSaveForLaterProps {
    className?: string;
    isAffiliate?: boolean;
}

function ProductCardSaveForLater({
    className,
    isAffiliate,
}: ProductCardSaveForLaterProps): React.ReactElement | null {
    const {
        id,
        image_url: imageURL,
        app_deeplink_path: appDeeplinkPath,
        in_stock: inStock,
        sale_discount: saleDiscount,
    } = useProductCardContext();

    const productStatus: ProductStatus = !inStock
        ? "out_of_stock"
        : saleDiscount
        ? "on_sale"
        : "in_stock";

    return (
        <div className={clsx(styles.saveForLater, className)}>
            {canUseMembership() && (
                <SaveForLaterButton
                    type="icon"
                    isLoggedIn={userProfiler.isLoggedIn()}
                    pageSource="feed"
                    productId={id.toString()}
                    productStatus={productStatus}
                    productImageURL={imageURL?.toString()}
                    isAffiliate={isAffiliate}
                    appDeeplinkPath={appDeeplinkPath}
                />
            )}
        </div>
    );
}

export default ProductCardSaveForLater;
