import React from "react";
import { useDispatch } from "react-redux";
import { KevelCampaignControl } from "web/react/components/kevel-campaign-control/kevel-campaign-control";
import { useClickTracker } from "web/react/hooks/use-click-tracker/use-click-tracker";
import { EventLabel, useSaveForLater } from "web/react/hooks/use-save-for-later/use-save-for-later";
import withReduxProvider from "web/react/redux-provider";
import {
    CaptureType,
    openCustomerCaptureOverlay,
    ProductStatus,
} from "web/redux/ducks/customer-capture-overlay";
import environment from "web/script/modules/environment";
import SaveForLater from "./save-for-later";
import { TooltipControl } from "./tooltip-control";

export type PageSource = "pdp" | "feed" | "oos";
export type ButtonType =
    | "cta"
    | "icon"
    | "LEGACY_normal"
    | "LEGACY_small"
    | "LEGACY_stockAlert"
    | "LEGACY_tick";

interface BaseProps {
    captureType?: CaptureType;
    className?: string;
    cta?: string;
    pageSource: PageSource;
    productId: string;
    productStatus?: ProductStatus;
    type?: ButtonType;
    variant?: "emotional";
    showExpressCheckout?: boolean;
    bingTraffic?: boolean;
    productImageURL?: string;
    isAffiliate?: boolean;
    isCheckout?: boolean;
    appDeeplinkPath?: string;
    analyticsEventLabel?: string;
    beforeOnClick?: (any?) => void;
    productPosition?: number;
    linkId?: number;
}

function parsePageSource(pageSource: PageSource): EventLabel {
    switch (pageSource) {
        case "pdp":
            return EventLabel.PDP;
        case "feed":
            return EventLabel.FEED;
        case "oos":
            return EventLabel.OOS;
    }
}

function _AnonymousSFLButton({
    captureType,
    className,
    cta,
    pageSource,
    productId,
    productStatus,
    type = "LEGACY_normal",
    showExpressCheckout,
    bingTraffic,
    variant,
    productImageURL,
    isAffiliate,
    isCheckout,
    appDeeplinkPath,
    analyticsEventLabel,
    beforeOnClick,
    productPosition,
    linkId,
}: BaseProps): JSX.Element {
    const dispatch = useDispatch();
    const trackClick = useClickTracker(
        "save_for_later",
        "add_item",
        parsePageSource(pageSource),
        false,
        { currency: environment.get("currencyProps.currencyCode"), product_id: productId }
    );

    const membershipAnalyticsEventLabel = analyticsEventLabel || "s4l_app_signup";

    function handleClick(): void {
        dispatch(
            openCustomerCaptureOverlay({
                productId: productId,
                productImageUrl: productImageURL,
                captureType: captureType || `signup_${pageSource}_save`,
                productStatus: productStatus,
                appDeeplinkPath: appDeeplinkPath,
                analyticsEventLabel: membershipAnalyticsEventLabel,
            })
        );
    }

    return (
        <KevelCampaignControl
            captureType={captureType}
            contextType={"save_for_later"}
            defaultAction={handleClick}
            options={{
                isAffiliate,
                isIcon: isCheckout,
            }}
            beforeOnClick={(event) => {
                beforeOnClick?.();
                trackClick(event);
            }}
            analyticsEventLabel={membershipAnalyticsEventLabel}
            appDeepLinkPath={appDeeplinkPath}
            productImageUrl={productImageURL}
            pageSource={pageSource}
            productId={productId}
        >
            <div data-testid="save-for-later-button">
                <TooltipControl
                    data-testid="tooltip-control"
                    pageSource={pageSource}
                    productPosition={productPosition}
                    linkId={linkId}
                >
                    <SaveForLater
                        className={className}
                        cta={cta}
                        isSaved={false}
                        type={type}
                        variant={variant}
                        showExpressCheckout={showExpressCheckout}
                        bingTraffic={bingTraffic}
                    />
                </TooltipControl>
            </div>
        </KevelCampaignControl>
    );
}

export const AnonymousSFLButton = withReduxProvider(_AnonymousSFLButton);

type AuthenticatedSFLButtonProps = BaseProps & {
    initialIsSaved?: boolean;
};

function _AuthenticatedSFLButton({
    className,
    cta,
    initialIsSaved,
    pageSource,
    productId,
    variant,
    type = "cta",
    showExpressCheckout,
    bingTraffic,
    beforeOnClick,
    productPosition,
    linkId,
}: AuthenticatedSFLButtonProps): JSX.Element {
    const { isSaved, toggleSaveForLater, isSaving } = useSaveForLater(
        productId,
        parsePageSource(pageSource),
        initialIsSaved
    );

    return (
        <span data-testid="save-for-later-button">
            <TooltipControl
                data-testid="tooltip-control"
                pageSource={pageSource}
                productPosition={productPosition}
                linkId={linkId}
                isSaved={isSaved}
            >
                <SaveForLater
                    className={className}
                    cta={cta}
                    variant={variant}
                    isSaved={isSaved}
                    isSaving={isSaving}
                    onClick={() => {
                        beforeOnClick?.();
                        toggleSaveForLater();
                    }}
                    type={type}
                    showExpressCheckout={showExpressCheckout}
                    bingTraffic={bingTraffic}
                />
            </TooltipControl>
        </span>
    );
}

export const AuthenticatedSFLButton = withReduxProvider(_AuthenticatedSFLButton);

type SaveForLaterButtonProps = BaseProps & {
    isLoggedIn: boolean;
    initialIsSaved?: boolean;
};

function SaveForLaterButton({
    isLoggedIn,
    initialIsSaved,
    ...props
}: SaveForLaterButtonProps): JSX.Element {
    if (isLoggedIn) {
        return <AuthenticatedSFLButton initialIsSaved={initialIsSaved} {...props} />;
    } else {
        return <AnonymousSFLButton {...props} />;
    }
}

export default SaveForLaterButton;
