import {
    ANDROID_SIGNUP_CAPTURE_TYPES,
    AuthType,
    CaptureType,
    ProductStatus,
} from "web/redux/ducks/customer-capture-overlay";
import { gettext } from "web/script/modules/django-i18n";
import url from "web/script/utils/url";

interface GetCopyDetailsOptions {
    name?: string;
    blockType?: string;
    browserMobile?: boolean;
    productStatus?: ProductStatus;
}

interface GetCopyDetailReturn {
    submitText: string;
    title: string;
    subtitle: string;
}

export function getCopyDetails(
    captureType: string,
    options?: GetCopyDetailsOptions
): GetCopyDetailReturn {
    let submitText!: string;
    let title!: string;
    let subtitle!: string;

    switch (captureType) {
        case "pdp_show_more":
        case "feeds_show_more":
        case "feeds_product_card":
            title = gettext("member_signup.member_save_products.title");
            submitText = gettext("general.continue");
            break;
        case "signup_du":
        case "signup_du_banner":
            title = gettext("account.register.follow_products_deals_designer.title", {
                designer_name: options?.name,
            });
            submitText = gettext("account.register.return_lead_btn_follow_products_designer");
            break;
        case "signup_su":
            title = gettext("account.register.follow_products_deals_store.title", {
                retailer_name: options?.name,
            });
            submitText = gettext("account.register.return_lead_btn_follow_products_store");
            break;
        case "signup_pdp_save":
        case "signup_feed_save":
        case "signup_lead_save":
        case "signup_oos_save":
        case "stock_alert_lead_return":
            if (options?.productStatus === "on_sale") {
                title = gettext("member_signup.modal.on_sale.title");
                submitText = gettext("general.continue");
            } else if (options?.productStatus === "out_of_stock") {
                title = gettext("member_signup.modal.out_of_stock.title");
                submitText = gettext("general.continue");
            } else {
                title = gettext("member_signup.modal.in_stock.title");
                submitText = gettext("general.continue");
            }
            break;
        case "signup_search":
            title = gettext("account.register.return_lead_title_follow_search", {
                search_terms: options?.name,
            });
            submitText = gettext("account.register.return_lead_btn_follow_search");
            break;
        case "aspirer_acquisition_campaign":
            title = gettext("member_signup.modal.aspirer_acquisition.title");
            subtitle = gettext("member_signup.modal.aspirer_acquisition.subtitle");
            submitText = gettext("general.continue");
            break;
    }

    return { submitText, title, subtitle };
}

export function getTypeDetails(captureType?: CaptureType): AuthType {
    switch (captureType) {
        case "signup_pdp_save":
        case "signup_feed_save":
        case "stock_alert_lead_return":
            return "save_for_later";
        case "signup_lead_save":
            return "save_for_later_lead_return";
        case "signup_oos_save":
            return "back_in_stock";
        case "signup_du":
            return "designer_update_lyst";
        case "signup_su":
            return "store_update_lyst";
        case "aspirer_acquisition_campaign":
            return "aspirer_acquisition_campaign";
    }
    return "normal";
}

export function generateSearchParams({ next, captureType, searchTerm, searchGender }): {
    searchTermParam: string | null;
    searchGenderParam: string | null;
    nextParam: string;
} {
    const parsedURL = url.parse(window.location.toString());
    // work out what the 'next' parameter should be
    parsedURL.searchParams.set("loggedin", "true");
    // avoid 'instant-notification' showing for these capture types
    if (!ANDROID_SIGNUP_CAPTURE_TYPES.includes(captureType as CaptureType)) {
        parsedURL.searchParams.set("area", "heart");
    }

    const path = next || parsedURL.path;
    const params = url.toQueryString(parsedURL.searchParams);
    const nextParam = next || `${path}${params && `?${params}`}`;
    const searchTermParam = searchTerm || parsedURL.searchParams.get("q");
    const searchGenderParam =
        searchGender ||
        parsedURL.searchParams.get("gender") ||
        parsedURL.searchParams.get("meta_gender");

    return { searchTermParam, searchGenderParam, nextParam };
}

export function shouldTruncateAnalyticsCategory(captureType: CaptureType): boolean {
    // Check if we should not truncate the event. TODO: Remove. See: https://lyst.atlassian.net/browse/BEAM-421
    const DO_NOT_TRUNCATE = ["aspirer_acquisition_campaign"];

    return !DO_NOT_TRUNCATE.includes(captureType);
}
