import React, { useEffect } from "react";
import useOneTapScript from "web/react/hooks/use-google-one-tap/use-google-one-tap";
import { useUrlData } from "web/react/hooks/use-url-data/use-url-data";
import { AuthType } from "web/redux/ducks/customer-capture-overlay";
import analytics from "web/script/analytics/analytics";
import environment from "web/script/modules/environment";
import userProfiler from "web/script/modules/userprofiler";

declare global {
    interface Window {
        google: any;
        [key: string]: any;
    }
}

interface IGoogleCallbackResponse {
    clientId?: string;
    credential?: string;
    select_by?: string;
}

const googleClientScriptURL = "https://accounts.google.com/gsi/client";

function googleOneTapAnalytics(notification: any): void {
    if (notification.getMomentType() !== "skipped") {
        analytics.event("google_one_tap", "shown");
    }
}

function processGoogleCredential(
    data: IGoogleCallbackResponse,
    currentUrl: URL | undefined,
    authType: AuthType
): void {
    if (data?.credential && currentUrl) {
        const fullUrl = currentUrl.pathname + currentUrl.search;
        try {
            // Create a hidden form
            const form = document.createElement("form");
            const action = `${
                window.location.origin
            }/social/complete/google-one-tap/?type=${authType}&next=${encodeURIComponent(fullUrl)}`;
            form.method = "POST";
            form.action = action;

            // Create a hidden input for the credential
            const input = document.createElement("input");
            input.type = "hidden";
            input.name = "id_token";
            input.value = data.credential;

            // Append the credential input to the form
            form.appendChild(input);

            // Append the form to the body
            document.body.appendChild(form);

            // Submit the form
            form.submit();

            // Remove the form from the DOM (optional, as the page will reload)
            document.body.removeChild(form);
        } catch (e) {
            console.error("Failed to log in with Google One Tap:", e);
        }
    }
}

interface GoogleOneTapProps {
    // onError?: (error?: any) => void;
    disabled?: boolean;
    onSuccess?: (response: any) => void;
    googleAccountConfigs: any;
    disableCancelOnUnmount?: boolean;
    client_id: string;
}

export default function GoogleOneTap({
    googleAccountConfigs,
    disableCancelOnUnmount = false,
}: GoogleOneTapProps): JSX.Element {
    const urlData = useUrlData();
    const script = useOneTapScript(googleClientScriptURL);
    const pageType = environment.get("pageType");

    useEffect(() => {
        if (userProfiler.isLoggedIn()) {
            return;
        }

        let authType: AuthType = "normal";
        if (pageType === "homepage") {
            authType = "google_one_tap_homepage";
        } else if (pageType === "feed") {
            authType = "google_one_tap_feeds";
        } else if (pageType === "search") {
            authType = "google_one_tap_search";
        }

        if (window.google && script === "ready") {
            window.google.accounts.id.initialize({
                client_id: googleAccountConfigs.clientId,
                callback: (response: IGoogleCallbackResponse) =>
                    processGoogleCredential(response, urlData?.newURL, authType),
                cancel_on_tap_outside: false,
                context: "signin",
                itp_support: true,
                sign_up_language: environment.get("language"),
                moment_callback: googleOneTapAnalytics,
                prompt_parent_id: "g_id_onload",
            });

            window.google.accounts.id.prompt();

            return () => {
                if (!disableCancelOnUnmount) {
                    window.google.accounts.id.cancel();
                }
            };
        }
    }, [script, googleAccountConfigs, disableCancelOnUnmount, urlData?.newURL]);

    return (
        <div
            id="g_id_onload"
            data-prompt_parent_id="g_id_onload"
            data-cancel_on_tap_outside="false"
            style={{
                position: "fixed",
                top: "32px",
                right: "32px",
                width: "auto",
                height: "auto",
                zIndex: "1001",
            }}
        />
    );
}
