import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { MemoryRouter, Route, Routes, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import CloseButton from "web/react/components/buttons/close-button/close-button";
import LazyModal from "web/react/components/lazy-modal/lazy-modal";
import SoftSignupForm from "web/react/components/soft-signup-dialog/soft-signup-form";
import { ValuePropositionAnimation } from "web/react/components/value-proposition-animation";
import { View } from "web/react/emo/view";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import { ForgotPasswordEmailSentScreen } from "web/react/pages/account/signup-or-login-page/forgot-password-email-sent-screen";
import { ForgotPasswordScreen } from "web/react/pages/account/signup-or-login-page/forgot-password-screen";
import { LoginEmailSentScreen } from "web/react/pages/account/signup-or-login-page/login-email-sent-screen";
import { LoginScreen } from "web/react/pages/account/signup-or-login-page/login-screen";
import { CaptureType, closeCustomerCaptureOverlay } from "web/redux/ducks/customer-capture-overlay";
import analytics from "web/script/analytics/analytics";
import * as styles from "./soft-signup-dialog.css";
import { shouldTruncateAnalyticsCategory } from "./soft-signup-utils";

interface SoftSignupRoutesProps {
    showSocialAuth: boolean;
    legalTextKey: string;
}

function SoftSignupRoutes({ showSocialAuth, legalTextKey }: SoftSignupRoutesProps): JSX.Element {
    const [email, setEmail] = useState("");
    const location = useLocation();
    const { isTabletViewport, isDesktopViewport } = useDomViewport();

    return (
        <TransitionGroup className={styles.transition}>
            <CSSTransition
                key={location.key}
                classNames={{
                    enter: styles.enter,
                    enterActive: styles.enterActive,
                    exit: styles.exit,
                    exitActive: styles.exitActive,
                }}
                timeout={{ enter: 1000, exit: 500 }}
                mountOnEnter
                unmountOnExit
            >
                <View width="full">
                    <Routes location={location}>
                        <Route
                            index
                            element={
                                <SoftSignupForm
                                    email={email}
                                    setEmail={setEmail}
                                    showSocialAuth={showSocialAuth}
                                    legalTextKey={legalTextKey}
                                />
                            }
                        />
                        {(isTabletViewport || isDesktopViewport) && (
                            <>
                                <Route path="/login" element={<LoginScreen email={email} />} />
                                <Route
                                    path="/login/email-sent"
                                    element={<LoginEmailSentScreen email={email} />}
                                />
                                <Route
                                    path="/forgot-password"
                                    element={
                                        <ForgotPasswordScreen onSuccess={setEmail} email={email} />
                                    }
                                />
                                <Route
                                    path="/forgot-password/email-sent"
                                    element={<ForgotPasswordEmailSentScreen email={email} />}
                                />
                            </>
                        )}
                    </Routes>
                </View>
            </CSSTransition>
        </TransitionGroup>
    );
}

interface SoftSignupDialogProps {
    captureType: CaptureType;
    overlayOpen: boolean;
    blockType?: string;
    analyticsEventLabel?: string;
    analyticsEventCategory?: string;
    imageSrc?: string;
    showSocialAuth?: boolean;
    legalTextKey?: string;
}

export const SoftSignupDialog = ({
    captureType,
    blockType,
    analyticsEventCategory,
    analyticsEventLabel,
    overlayOpen,
    imageSrc,
    showSocialAuth = true,
    legalTextKey = "",
}: SoftSignupDialogProps): JSX.Element => {
    const dispatch = useDispatch();
    // Check if we should not truncate the event. TODO: Remove. See: https://lyst.atlassian.net/browse/BEAM-421
    const truncate = captureType ? shouldTruncateAnalyticsCategory(captureType) : true;
    const { isDesktopViewport } = useDomViewport();
    const analyticsCategoryPrefix = isDesktopViewport ? "desktop_signup" : "mobile_signup";

    const analyticsCategory = analyticsEventCategory
        ? `${analyticsCategoryPrefix}_${analyticsEventCategory}`
        : analyticsCategoryPrefix;

    function onAfterOpen(): void {
        analytics.event(
            captureType,
            "open_signup_form",
            "open_overlay",
            false,
            {},
            "",
            {},
            [],
            false,
            truncate
        );
        analytics.event(
            analyticsCategory,
            "shown",
            analyticsEventLabel,
            false,
            {},
            "",
            {},
            [],
            false,
            truncate
        );
    }

    function onClose(): void {
        analytics.event(
            captureType,
            "close_signup_form",
            "exit_signup",
            false,
            {},
            "",
            {},
            [],
            false,
            truncate
        );
        analytics.event(
            analyticsCategory,
            "closed",
            analyticsEventLabel,
            false,
            {},
            "",
            {},
            [],
            false,
            truncate
        );
        dispatch(closeCustomerCaptureOverlay());
    }

    const appElement = document.body?.querySelector<HTMLDivElement>(".site-wrapper");

    const closable = blockType !== "hard_block_after_action";

    return (
        <LazyModal
            appElement={appElement || undefined}
            className={styles.modal}
            isOpen={overlayOpen}
            onAfterOpen={onAfterOpen}
            onRequestClose={onClose}
            overlayClassName={styles.overlay}
            shouldCloseOnOverlayClick={closable}
            shouldCloseOnEsc={closable}
            noScroll={false}
        >
            {closable && (
                <div className={styles.closeContainer}>
                    <CloseButton
                        useDefaultStyle={false}
                        className={styles.closeButton}
                        onClick={onClose}
                    />
                </div>
            )}
            <div data-testid="signup-overlay">
                <div className={styles.layout}>
                    <div className={styles.formContainer}>
                        <MemoryRouter>
                            <SoftSignupRoutes
                                showSocialAuth={showSocialAuth}
                                legalTextKey={legalTextKey}
                            />
                        </MemoryRouter>
                    </div>
                    {isDesktopViewport &&
                        (imageSrc ? (
                            <div className={styles.imageContainer}>
                                <img src={imageSrc} />
                            </div>
                        ) : (
                            <div className={styles.animationContainer}>
                                <ValuePropositionAnimation />
                            </div>
                        ))}
                </div>
            </div>
        </LazyModal>
    );
};
