import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useNavigationContext } from "web/react/components/navigation/navigation.context";
import { Heading } from "web/react/emo/heading";
import { ListItem } from "web/react/emo/list-item";
import { Text } from "web/react/emo/text";
import { VStack } from "web/react/emo/v-stack";
import { View } from "web/react/emo/view";
import { gettext } from "web/script/modules/django-i18n";
import {
    BasicMenuSerializer,
    MenuLinkSerializer,
    MenuSerializer,
    SubMenuSerializer,
} from "web/types/serializers";
import * as styles from "./category-menu.css";

export const CATEGORY_LIMIT = 12;

const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
letters.push("0-9");

interface MultiColumnCategoryProps {
    items: MenuLinkSerializer[] | BasicMenuSerializer[];
    title?: string;
    callback?: (menu) => void;
}

function MultiColumnCategory({
    items,
    title,
    callback = () => {},
}: MultiColumnCategoryProps): React.ReactElement {
    return (
        <div className={styles.brands}>
            <View marginTop="xs" height="full">
                {title && (
                    <View paddingLeft="sm">
                        <Heading textStyle="subhead" as="h2">
                            {title}
                        </Heading>
                    </View>
                )}
                <View marginTop="md" height="full">
                    <ul className={styles.twoColumnList}>
                        {items.map((link) => (
                            <ListItem as="a" href={link.url} key={link.url} onClick={callback}>
                                <Text textStyle="body-3-small" underline={link.text === "View All"}>
                                    {link.text}
                                </Text>
                            </ListItem>
                        ))}
                    </ul>
                </View>
            </View>
        </div>
    );
}

interface MainCategoryProps {
    category: SubMenuSerializer;
    expandedCategories: { [name: string]: boolean };
    setExpandedCategories: React.Dispatch<React.SetStateAction<{ [name: string]: boolean }>>;
}

function MainCategory({
    category,
    expandedCategories,
    setExpandedCategories,
}: MainCategoryProps): React.ReactElement {
    const totalItems = category.links.length || 0;
    const hasHiddenItems = totalItems >= CATEGORY_LIMIT;
    const hideItems = hasHiddenItems && !expandedCategories[category.name];

    const { handleCategoryAnalytics } = useNavigationContext();

    return (
        <div className={styles.subcategories}>
            <ul>
                {Array.isArray(category.links) &&
                    category.links.map((link, index) => {
                        const shouldHide = hideItems && index > CATEGORY_LIMIT - 1;
                        if (link.url === "") return;
                        return (
                            <ListItem
                                as="a"
                                href={link.url}
                                style={shouldHide ? { display: "none" } : undefined}
                                key={link.url}
                                data-testid={`${category.name}-item`}
                                onClick={() => {
                                    handleCategoryAnalytics("product_category");
                                }}
                                showChevron
                            >
                                <Text
                                    textStyle="body-3-small"
                                    color={link.is_sale ? "error" : "primary"}
                                >
                                    {link.text}
                                </Text>
                            </ListItem>
                        );
                    })}
            </ul>

            {hideItems && (
                <ListItem
                    as="button"
                    onClick={(): void =>
                        setExpandedCategories({
                            ...expandedCategories,
                            [category.name]: true,
                        })
                    }
                    data-testid={`${category.name}-more`}
                >
                    <Text textStyle="body-3-small" underline>
                        {`+ ${totalItems - CATEGORY_LIMIT} `}
                        {/* More */}
                        {gettext("burger_menu.more_category_link")}
                    </Text>
                </ListItem>
            )}
        </div>
    );
}

export interface CategoryMenuProps {
    menuData: MenuSerializer;
    featuredDesignersUrl: string;
    selectedCategory: SubMenuSerializer | null;
}

export function CategoryMenu({
    menuData,
    featuredDesignersUrl,
    selectedCategory,
}: CategoryMenuProps): React.ReactElement | null {
    const menus = menuData.menus;
    const [selectedTrendMenu, setSelectedTrendMenu] = useState<BasicMenuSerializer | null>(null);
    const [expandedCategories, setExpandedCategories] = useState({});

    const { showMenu, setShowMenu, handleCategoryAnalytics } = useNavigationContext();

    function selectType(type: SubMenuSerializer): void {
        setSelectedTrendMenu(null);

        if (type.trend_menus && type.trend_menus.length === 1) {
            setSelectedTrendMenu(type.trend_menus[0]);
        }
    }

    useEffect(() => {
        setSelectedTrendMenu(null);

        if (menus?.length && menus[0]?.trend_menus?.length === 1) {
            setSelectedTrendMenu(menus[0].trend_menus[0]);
        }

        // [CW-763] Update the selected type when the menu data changes (mainly between genders)
        const newType = menus.find((type) => type.name === selectedCategory?.name) || menus[0];
        selectType(newType);
    }, [menus, selectedCategory?.name]);

    function useRenderTrends(selectedTrendMenu): React.ReactElement | null {
        const trendsLength = selectedCategory?.trend_menus?.length || 0;

        function callback(menu): void {
            setSelectedTrendMenu(menu);
            handleCategoryAnalytics("featured_category");
        }

        if (
            !selectedCategory?.trend_menus ||
            !Array.isArray(selectedCategory?.trend_menus) ||
            !trendsLength
        ) {
            return null;
        }

        return (
            <>
                {trendsLength === 1 ? (
                    <MultiColumnCategory
                        items={selectedCategory.trend_menus[0].links}
                        title={selectedCategory.trend_menus[0].display_name}
                        callback={callback}
                    />
                ) : (
                    selectedCategory.trend_menus.map((menu, i) => (
                        <>
                            <MultiColumnCategory
                                key={menu.display_name}
                                items={menu.links}
                                title={menu.display_name}
                                callback={callback}
                            />
                            {i !== trendsLength - 1 && <div className={styles.divider} />}
                        </>
                    ))
                )}

                {/* If we have more than 1 trend menu we will render a third column when a trend menu is selected */}
                {selectedCategory.trend_menus.length > 1 && (
                    <ul>
                        {selectedTrendMenu?.links.map((link) => {
                            return (
                                <li key={link.url}>
                                    <a href={link.url}>{link.text}</a>
                                </li>
                            );
                        })}
                    </ul>
                )}
            </>
        );
    }

    useEffect(() => {
        if (showMenu) {
            document.body.classList.add("no-scroll");
        } else {
            document.body.classList.remove("no-scroll");
        }
    }, [showMenu]);

    function onOverlayInteract(event): void {
        const navContainer = document.getElementById("desktopMenuContent");

        if (!navContainer?.contains(event.target)) {
            setShowMenu(null);
        }
    }

    const trendsContent = useRenderTrends(selectedTrendMenu);

    return (
        <div
            className={clsx(styles.menuLayer, {
                [styles.hide]: showMenu !== "desktop",
            })}
            onMouseOver={onOverlayInteract}
            data-testid="overlay"
        >
            <div className={styles.container}>
                <nav id="desktopMenuContent">
                    <View paddingX="xxl" paddingY="lg" className={styles.content}>
                        {selectedCategory?.name === "popular_brands" ? (
                            <div className={styles.menu}>
                                <MultiColumnCategory
                                    items={selectedCategory.links}
                                    title={gettext("app_header_menu.popular_brands")} // Popular Brands
                                    callback={() => {
                                        handleCategoryAnalytics("product_category");
                                    }}
                                />
                                <div className={styles.divider} />
                                <VStack spacing="xs">
                                    <Heading textStyle="subhead" as="h2">
                                        {/* Brands A-Z */}
                                        {gettext("app_header_menu.brands_a_z")}
                                    </Heading>
                                    <div className={styles.brandLetterLinks}>
                                        {letters.map((letter, index) => (
                                            <ListItem
                                                className={
                                                    index !== letters.length - 1
                                                        ? styles.brandLetterLink
                                                        : styles.brandNumberLink
                                                }
                                                as="a"
                                                key={`brand-initial-${letter}`}
                                                href={`${featuredDesignersUrl}#${letter}`}
                                            >
                                                <Text textStyle="body-3-small">{letter}</Text>
                                            </ListItem>
                                        ))}
                                    </div>
                                </VStack>
                            </div>
                        ) : (
                            <div className={styles.menu}>
                                {selectedCategory && (
                                    <MainCategory
                                        category={selectedCategory}
                                        expandedCategories={expandedCategories}
                                        setExpandedCategories={setExpandedCategories}
                                    />
                                )}
                                <div className={styles.divider} />
                                {trendsContent}
                            </div>
                        )}
                    </View>
                </nav>
            </div>
        </div>
    );
}
