import clsx from "clsx";
import React from "react";
import * as styles from "web/react/components/rail/rail.css";
import { HStack } from "web/react/emo/h-stack";

interface RailItemProps {
    children: React.ReactNode;
    isPillHidden: boolean;
    listItemClassName?: string;
}

export function RailItem({
    children,
    isPillHidden,
    listItemClassName,
}: RailItemProps): JSX.Element {
    return (
        <li
            className={clsx(
                // Make list item available for SEO purposes
                // when pill is hidden behind show more button
                { [styles.seoLinks]: isPillHidden, [styles.railItem]: !isPillHidden },
                listItemClassName
            )}
            data-testid="rail-child"
        >
            {children}
        </li>
    );
}
interface BaseRailProps {
    children: React.ReactNode;
    wrap: boolean;
    onScroll: () => void;
    railRef: React.RefObject<HTMLOListElement>;
    isPillHidden: (index: number) => boolean;
    listItemClassName?: string;
    spacing?: "xs" | "md";
}

export function BaseRail({
    children,
    wrap,
    onScroll,
    railRef,
    isPillHidden,
    listItemClassName,
    spacing = "xs",
}: BaseRailProps): JSX.Element {
    return (
        <ol
            className={clsx(styles.rail, {
                [styles.overflow]: !wrap,
            })}
            onScroll={onScroll}
            ref={railRef}
        >
            <HStack spacing={spacing} wrap={wrap}>
                {React.Children.map(children, (child, index) => (
                    <RailItem
                        key={index}
                        isPillHidden={isPillHidden(index)}
                        data-testid="rail-child"
                        listItemClassName={listItemClassName}
                    >
                        {child}
                    </RailItem>
                ))}
            </HStack>
        </ol>
    );
}
