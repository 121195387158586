import clsx from "clsx";
import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useNavigationContext } from "web/react/components/navigation/navigation.context";
import { Text } from "web/react/emo/text";
import { MenuLinkSerializer, SubMenuSerializer } from "web/types/serializers";
import * as styles from "./sticky-categories.css";

export interface StickyCategoriesProps {
    menus: SubMenuSerializer[];
    links: MenuLinkSerializer[];
    selectedCategory: SubMenuSerializer | null;
    setSelectedCategory: Dispatch<SetStateAction<SubMenuSerializer | null>>;
}

export function StickyCategories({
    menus,
    links,
    selectedCategory,
    setSelectedCategory,
}: StickyCategoriesProps): React.ReactElement {
    const { showMenu, setShowMenu, handleCategoryAnalytics } = useNavigationContext();

    // This is required for tablets in horizontal mode
    // First touch click would be to open the menu
    // Second touch click would be to close the menu
    const [shouldRedirect, setShouldRedirect] = useState(true);

    const onHover = useCallback(
        function (menu: SubMenuSerializer): void {
            setSelectedCategory(menu);
            setShowMenu("desktop");
            handleCategoryAnalytics("product_type", "impression");
        },
        [handleCategoryAnalytics, setSelectedCategory, setShowMenu]
    );

    const onClick = useCallback(
        function (event, redirect: boolean): void {
            if (!redirect && !shouldRedirect) {
                event.preventDefault();
                return;
            }
            handleCategoryAnalytics("product_type");
        },
        [shouldRedirect, handleCategoryAnalytics]
    );

    const onHoverLink = useCallback(
        function (): void {
            setSelectedCategory(null);
            setShowMenu(null);
        },
        [setSelectedCategory, setShowMenu]
    );

    const onTouchClick = useCallback(
        function (menu: SubMenuSerializer): void {
            setShouldRedirect(false);

            if (showMenu === null) {
                onHover(menu);
            } else {
                onHoverLink();
            }
        },
        [onHover, onHoverLink, showMenu]
    );

    return (
        <nav>
            <div className={styles.container}>
                {menus.map((menu, i) => (
                    <a
                        data-testid={`category-${menu.display_name}`}
                        className={clsx(styles.stickyItem, {
                            [styles.active]: selectedCategory?.display_name === menu.display_name,
                        })}
                        key={`${i}-${menu.display_name}`}
                        onMouseEnter={() => onHover(menu)}
                        onTouchStart={() => onTouchClick(menu)}
                        href={menu.url}
                        onClick={(event) => onClick(event, false)}
                    >
                        <Text textStyle="body-3-small">{menu.display_name}</Text>
                    </a>
                ))}
                {links.map((link, i) => (
                    <a
                        data-testid={`link-${link.text}`}
                        className={styles.stickyItem}
                        key={`${i}-${link.text}`}
                        href={link.url}
                        onMouseEnter={onHoverLink}
                        onClick={(event) => onClick(event, true)}
                    >
                        <Text textStyle="body-3-small" color={link.is_sale ? "error" : "primary"}>
                            {link.text}
                        </Text>
                    </a>
                ))}
            </div>
        </nav>
    );
}
