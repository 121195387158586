import {
    CookieConsent,
    CookieConsentLevel,
    setCookieConsent,
} from "web/redux/ducks/cookie-consent";
import store from "web/redux/store";
import analytics from "web/script/analytics/analytics";
import environment from "./environment";

/** Maps our in-house cookies to cookie platform's cookie categories */
const COOKIE_CATEGORY_MAP = {
    advertisement: "C0004", // Targeting Cookies
    analytics: "C0002", // Performance Cookies
    customization: "C0003", // Functional Cookies
    personalizedAds: "V2STACK42", // Personalized Ads Cookies
};

const COOKIE_BANNER_SELECTOR = '[aria-describedby="onetrust-policy-text"]';
const COOKIE_BANNER_LOAD_EVENT_NAME = "OneTrustIsLoaded";

export function getConsentLevel(accepts: boolean): CookieConsentLevel {
    return accepts ? CookieConsentLevel.Accepts : CookieConsentLevel.Rejects;
}

export interface SelectedState {
    adCookie: boolean;
    analyticsCookie: boolean;
    customizationCookie: boolean;
    personalizedAdsCookie: boolean;
}

function _sendAnalyticsEvents(updatedState: SelectedState, source: string): void {
    if (updatedState.customizationCookie && updatedState.adCookie && updatedState.analyticsCookie) {
        // The same event as cookie consent even if it is done via cookie settings -
        // so we know what % of users accept all cookies
        analytics.event("Cookie consent", "Clicked", "Hide");
    } else if (
        !updatedState.customizationCookie &&
        !updatedState.adCookie &&
        !updatedState.analyticsCookie
    ) {
        // Send analytics when only required cookies are accepted + the source
        analytics.event(source, "clicked", "accept_required");
    } else {
        // this event is sent when we are not in the reject all/accept all cookies conditions
        analytics.event(source, "clicked", "save_changes", false, {
            advertising: getConsentLevel(updatedState.adCookie) === CookieConsentLevel.Accepts,
            analytics: getConsentLevel(updatedState.analyticsCookie) === CookieConsentLevel.Accepts,
            siteCustomization:
                getConsentLevel(updatedState.customizationCookie) === CookieConsentLevel.Accepts,
        });
    }
}

/**
 * Handles logic when OnConsentChanged is triggered, which includes the following:
 *
 * - Creating the different types of lyst cookies depending on the CMP cookie value
 * (customization, advertisement and analytics) using the cookie-consent logic
 * - Send analytic events with the accepted/rejected cookies and the source
 * (from the cookie banner or the preference center).
 */
function _cookieConsentListener(event: CustomEvent, country: string | undefined): void {
    // In the US customization cookie will always be accepted in OneTrust
    // To keep the same logic as we had before, we need to check the country
    // and set the cookieConsent based on the advertisement cookie
    const customizationCookie =
        country === "US"
            ? event.detail.includes(COOKIE_CATEGORY_MAP.advertisement)
            : event.detail.includes(COOKIE_CATEGORY_MAP.customization);

    const updatedState: SelectedState = {
        adCookie: event.detail.includes(COOKIE_CATEGORY_MAP.advertisement),
        analyticsCookie: event.detail.includes(COOKIE_CATEGORY_MAP.analytics),
        customizationCookie: customizationCookie,
        personalizedAdsCookie: event.detail.includes(COOKIE_CATEGORY_MAP.personalizedAds),
    };
    const cookieConsent: CookieConsent = {
        adCookie: getConsentLevel(updatedState.adCookie),
        analyticsCookie: getConsentLevel(updatedState.analyticsCookie),
        customizationCookie: getConsentLevel(updatedState.customizationCookie),
        personalizedAdsCookie: getConsentLevel(updatedState.personalizedAdsCookie),
    };
    store.dispatch(setCookieConsent(cookieConsent));

    // If the consent bar is in the DOM that means the user saw the banner
    let source = "cookie_settings";
    const cookieBanner = document.querySelector(COOKIE_BANNER_SELECTOR);
    if (cookieBanner) {
        // This block is entered only after the user has dismissed the cookie banner, that is, they have
        // re-instated their cookie consent through our CMP - either because it is their first time on
        // the website, or because they have cleared their cookies, or because their cookies have expired.
        source = "Cookie consent";

        // When the user dismisses the cookie banner, it is still present in the DOM.
        // Remove it so that next time the user changes their consent we don't go through this block again.
        cookieBanner.remove();

        // Send analytics event if the user dismisses the banner and are in the US.
        if (country === "US") {
            analytics.event("cookie_banner", "dismissed", "ccpa_banner");
            return;
        }
    }

    if (country !== "US") {
        _sendAnalyticsEvents(updatedState, source);
    } else {
        // eslint-disable-next-line no-lonely-if
        if (updatedState.analyticsCookie) {
            analytics.event("ccpa_toggle_share", "clicked", "accepts_cookies");
        } else {
            analytics.event("ccpa_toggle_share", "clicked", "rejected_cookies");
        }
    }
}

/**
 *  Sends an analytic everytime we display the one trust preference center.
 *
 * The preference center is accessible by either the banner or the footer.
 * We try to get the cookie banner element from the DOM: if it is there, it means the preference center has been
 * opened from the banner, if not, from the footer.
 */
function _sendPreferenceCenterAnalytics(country: string | undefined): void {
    const cookieBanner = document.querySelector(COOKIE_BANNER_SELECTOR);
    // Set to "banner" if the cookie settings modal is accessed via the banner
    const eventAction = country === "US" ? "shown_ccpa" : "shown_tcf";
    const eventLabel = cookieBanner !== null ? "banner" : "footer";
    analytics.event("cookie_settings", eventAction, eventLabel);
}

let isOneTrustInitialised = false;

export default {
    init: function () {
        // We apply our own logic on top of the CMP after listening to the event that is fired once
        // the banner is loaded. This allows us to apply additional logic such as sending our own
        // analytics when the cookies have been updated.
        window.addEventListener(COOKIE_BANNER_LOAD_EVENT_NAME, () => {
            if (!isOneTrustInitialised) {
                let country = environment.get("geoip_country");
                // Whenever a change is made on the cookie settings via the CMP, we need to update
                // the lyst cookies.
                window.OneTrust?.OnConsentChanged((event: CustomEvent<string[]>) => {
                    _cookieConsentListener(event, country);
                });
                // Whenever we display the cookie preference center modal, we send an event.
                window.addEventListener("OneTrustPCLoaded", () => {
                    _sendPreferenceCenterAnalytics(country);
                });

                isOneTrustInitialised = true;

                // The first time we show this consent banner, send an analytic event.
                analytics.event(
                    "Cookie consent",
                    country === "US" ? "Shown_ccpa" : "Shown_tcf",
                    undefined,
                    true
                );

                isOneTrustInitialised = true;
            }
        });
    },
};
