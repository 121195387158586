import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { SuggestionResult } from "web/react/components/navigation/search-bar/auto-suggest/suggestion-result";
import { HStack } from "web/react/emo/h-stack";
import { Heading } from "web/react/emo/heading";
import { Text } from "web/react/emo/text";
import { View } from "web/react/emo/view";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import requester from "web/script/modules/requester";
import { useAutoSuggestContext } from "./auto-suggest.context";
import * as styles from "./auto-suggest.css";

export function AutoSuggest(): React.ReactElement {
    enum ViewType {
        NONE = "NONE",
        RECENT = "RECENT",
        QUERY = "QUERY",
    }
    const {
        query,
        querySuggestions,
        profileSuggestions,
        recentSearches,
        setRecentSearches,
        isVisible,
        setRecentSearchesCleared,
    } = useAutoSuggestContext();

    const [currentView, setCurrentView] = useState<ViewType>(ViewType.NONE);
    const EVENT_CATEGORY = "top_nav";

    const { isDesktopViewport } = useDomViewport();

    function onMouseDown(e): void {
        e.preventDefault();
    }
    function clearRecentSearches(): void {
        requester("/rest_api/recent-searches/clear/", {
            method: "DELETE",
        }).then(function () {
            setRecentSearches([]);
            setRecentSearchesCleared(true);
            analytics.event(EVENT_CATEGORY, "clicked_clear", "recent_searches");
        });
    }

    useEffect(() => {
        if (!isVisible) {
            setCurrentView(ViewType.NONE);
            return;
        }

        if (recentSearches.length > 0 && query === "") {
            if (currentView === ViewType.RECENT) {
                return;
            }
            setCurrentView(ViewType.RECENT);
            analytics.event(EVENT_CATEGORY, "impression", "recent_searches");
        } else if (profileSuggestions.length > 0 && querySuggestions.length > 0 && query !== "") {
            if (currentView === ViewType.QUERY) {
                return;
            }
            if (profileSuggestions.length > 0) {
                setCurrentView(ViewType.QUERY);
                analytics.event(EVENT_CATEGORY, "impression", "top_result");
            }
            if (querySuggestions.length > 0) {
                setCurrentView(ViewType.QUERY);
                analytics.event(EVENT_CATEGORY, "impression", "other_suggestions");
            }
        } else {
            setCurrentView(ViewType.NONE);
            return;
        }
    }, [
        isVisible,
        recentSearches,
        profileSuggestions,
        querySuggestions,
        query,
        currentView,
        ViewType,
    ]);

    if (recentSearches.length > 0 && query === "") {
        return (
            <View
                className={clsx(styles.suggestionsContainer, {
                    hidden: !isVisible,
                })}
            >
                <View className={styles.suggestionsContent}>
                    <View
                        paddingTop={{ lg: "none", sm: "xxs" }}
                        paddingBottom={{ lg: "sm", sm: "xxs" }}
                        paddingX={{ sm: "sm", lg: "none" }}
                        className={clsx(styles.headingWrapper, styles.recentHeadingWrapper)}
                    >
                        <Heading textStyle={"subhead"}>
                            {
                                // "Recent searches"
                                gettext("search.suggestions.recent_searches.label")
                            }
                        </Heading>
                        {isDesktopViewport && <div className={styles.headingDivider} />}

                        <button onMouseDown={onMouseDown} onClick={clearRecentSearches}>
                            <Text underline textStyle={"small-link"} color="secondary">
                                {
                                    // "Clear"
                                    gettext("feed.filters.clear_btn_text")
                                }
                            </Text>
                        </button>
                    </View>
                    <View className={styles.suggestionList} paddingBottom={{ lg: "md", sm: "xxs" }}>
                        <HStack wrap>
                            {recentSearches.map((suggestion, index) => (
                                <SuggestionResult
                                    key={`recent_${index}_${suggestion.query}`}
                                    variant="recent"
                                    index={index}
                                    recentSearch={suggestion}
                                />
                            ))}
                        </HStack>
                    </View>
                </View>
            </View>
        );
    } else {
        return (
            <View
                className={clsx(styles.suggestionsContainer, {
                    hidden: !isVisible,
                })}
            >
                <View className={styles.suggestionsContent}>
                    <View
                        paddingTop={{ lg: "none", sm: "xxs" }}
                        paddingBottom={{ lg: "sm", sm: "xxs" }}
                        className={styles.headingWrapper}
                    >
                        {profileSuggestions.length > 0 && (
                            <Heading textStyle={"subhead"} className={styles.heading}>
                                {
                                    // "Top Result"
                                    gettext("search.suggestions.top_result.label")
                                }
                            </Heading>
                        )}
                    </View>

                    <View className={styles.suggestionList} paddingBottom={{ lg: "md", sm: "xxs" }}>
                        <HStack wrap>
                            {profileSuggestions.map((suggestion, index) => (
                                <SuggestionResult
                                    key={`profile_${index}_${suggestion.name}`}
                                    variant="profile"
                                    index={index}
                                    profileSuggestion={suggestion}
                                />
                            ))}
                        </HStack>
                    </View>
                    <View
                        paddingTop={{ lg: "none", sm: "sm" }}
                        paddingBottom={{ lg: "sm", sm: "xxs" }}
                        className={styles.headingWrapper}
                    >
                        {querySuggestions.length > 0 && (
                            <Heading textStyle={"subhead"} className={styles.heading}>
                                {
                                    // "Other Suggestions"
                                    gettext("search.suggestions.other_suggestions.label")
                                }
                            </Heading>
                        )}
                    </View>
                    <View
                        className={styles.suggestionList}
                        paddingBottom={{ lg: "none", sm: "md" }}
                    >
                        <HStack wrap>
                            {querySuggestions.map((suggestion, index) => (
                                <SuggestionResult
                                    key={`query_${index}_${suggestion.query}`}
                                    variant="query"
                                    index={profileSuggestions.length + index}
                                    querySuggestion={suggestion}
                                />
                            ))}
                        </HStack>
                    </View>
                </View>
            </View>
        );
    }
}
