import "web/react/emo/theme.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"web/react/emo/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6WUTW/bMAyG7/sVRHzZgHSwPizL3Em2JayH7bDuPjip06b5cJqkSdth/32gMxQzPZ0G+fRQpqiXfIX7rjvCz3cAV1c/xP52JcU5RUjSNP00gAIhsdpY4YdcIiRBhdQXQ64QkjJzOrdDrhGSIs1zlQ15hpAYlynB9hvK40sV5JDnCImvvA3lkFuEpCorGVj+guovrHBhyBuEJAuZy1n9M4REV9oaMeRzhKRW0kumwy3p4L3zbshb4qVVluVZEHc60+xed6RPJmrB7nWPkMhamcDqX1Kzgig5f+ibaF3KdFiRbiY4nw/5mvQvvcpZ3zd9HhdStn/bc21SNeRdRJ9dRJ/HiD77iD6HiD7HPn9h+dw+EQ8h82bIT/3+ShjGzz13tWL3eu7nKnc8/wtCIpwyJeOvpLP1ZWC6CXJXbqypucHIYSKTpa1ZgCyWOmE0K1WQx2QurWSzK3SkKEEuqx0tFjB9G4ILPEA+C1nQ3H+CjFb7uq55VUXsjObSisBHVcxih88vj4sKrBmC3OZzWizQxg4nv1WeFguQ4SpNiwXIcUVJiwXIcnlKiwXIc9rTYoFVrFHry1M7fm03CPu7WfM+ncKf76P+wPZsYz93sQJ3sT8eI6+7IBvm0pQFs6c4RHwujpGHR5ATq1wayYU+RawlzrExfo5N0kss8BppjUwjkkgREVHKyMBIhcD3agS9e2YwQ7AjaBCEHNEcQZgRtQhynLZAUOmINghqnHeGoMd75wh6XNktghmf1iLYcYYFQjGu947uNt58j3DztDwcWrjeHtcsuHwLfum2HQs+IFyfmj3cdIsjC60GSacw+dyuT+1xOW/ga/vUTqbwBqbg9stmPYVDsz1cHdr9csGSrf86ZwqT78tNe4Cv7Rm+dZtmO5lCT6bwr383gwv8ZyFb6hcfrg5BZxzuELLRzkcEM4J7BKEuM/frN5j8aoMHCgAA\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "web/react/emo/tooltip/tooltip.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"web/react/emo/tooltip/tooltip.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VSyW7CMBC95ytGSEhElVs7LG3NpX+ChtiARWJbtimJKv69ihNUsnDsKdG853mL5uss64PDUnrYsZpSW9EN/CQAdB4/AMZirkLNgW4TgFsCwOgYZC14S17va94jxRqvgjKaA+69KS5BNsQ95uejMxctOHyjWxCyY06cM3ZlyzQSjBPSEYdCXfyQk60ix6IQSh9H6CaiVyXCicNBBZIbHaQOzbTEinTIcrW2VatVEX9CYa4cqK3GYhOTMY+VURa1KrEN/Ncn89teM5zjIUgXC+rMcZjNts/7auvonLPsbjxOfagLycGbQomHcW4K4ybqheBQe4tO6vDsv2f3I/oMxnLIscgX940tSlMgMJitUniB/midprGfQh5Ct2diGRs/zFJ4g6wRmeAvWzDt+32sN9puDnYgvqZzILHJ/uPPfwo7yklgFGUcfeDtaTCnjqdppba+yajY3d/UmWBD/QWhFy8CHAQAAA==\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var anchorLeft = 'var(--_1y00px01)';
export var anchorTop = 'var(--_1y00px00)';
export var anchorWidth = 'var(--_1y00px02)';
export var offset = 'var(--_1y00px05)';
export var text = '_1y00px0a';
export var tooltip = '_1y00px07';
export var tooltipHeight = 'var(--_1y00px04)';
export var tooltipWidth = 'var(--_1y00px03)';
export var topEndPlacement = '_1y00px09';
export var topPlacement = '_1y00px08';