import { assignInlineVars } from "@vanilla-extract/dynamic";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { Text } from "web/react/emo/text";
import { useIntersectionObserver } from "web/react/hooks/use-intersection-observer/use-intersection-observer";
import useIsomorphicLayoutEffect from "web/react/hooks/use-isomorphic-layout-effect/use-isomorphic-layout-effect";
import * as styles from "./tooltip.css";

type TooltipPlacement = "top" | "top-end";

interface TooltipPortalProps {
    text: string;
    placement: TooltipPlacement;
    offset: number;
    anchorPosition: DOMRect | null;
    scrollTop: number;
    scrollLeft: number;
    parentRef?: React.RefObject<HTMLElement> | null;
}

function TooltipPortal({
    text,
    placement,
    offset,
    anchorPosition,
    scrollTop,
    scrollLeft,
    parentRef,
}: TooltipPortalProps): React.ReactElement {
    const anchorTop = anchorPosition ? anchorPosition.top - scrollTop : 0;
    const anchorLeft = anchorPosition ? anchorPosition.left - scrollLeft : 0;
    const anchorWidth = anchorPosition?.width;

    const tooltipPortalId = "tooltip-portal";
    const tooltipPosition = document.getElementById(tooltipPortalId)?.getBoundingClientRect();
    const tooltipWidth = tooltipPosition?.width;
    const tooltipHeight = tooltipPosition?.height;

    const container = parentRef && parentRef.current;

    return ReactDOM.createPortal(
        <div
            id={tooltipPortalId}
            className={clsx(styles.tooltip, {
                [styles.topPlacement]: placement === "top",
                [styles.topEndPlacement]: placement === "top-end",
            })}
            style={assignInlineVars({
                [styles.offset]: `${offset}px`,
                [styles.anchorTop]: `${anchorTop}px`,
                [styles.anchorLeft]: `${anchorLeft}px`,
                [styles.anchorWidth]: `${anchorWidth}px`,
                [styles.tooltipWidth]: `${tooltipWidth}px`,
                [styles.tooltipHeight]: `${tooltipHeight}px`,
            })}
        >
            <Text as="span" textStyle="body-3-small" className={styles.text}>
                {text}
            </Text>
        </div>,
        container || document.body
    );
}

interface TooltipProps {
    text: string;
    placement?: TooltipPlacement;
    offset?: number;
    delay?: number;
    duration?: number;
    show?: boolean;
    onShow?: () => void;
    parentRef?: React.RefObject<HTMLElement> | null;
    containerTop?: number | null;
    containerLeft?: number | null;
    children: React.ReactNode;
}

export function Tooltip({
    text,
    placement = "top",
    offset = 10,
    delay = 0,
    duration,
    show = true,
    onShow,
    parentRef,
    containerTop,
    containerLeft,
    children,
}: TooltipProps): React.ReactElement {
    const anchorRef = useRef<HTMLDivElement>(null);
    const [anchorPosition, setAnchorPosition] = useState<DOMRect | null>(null);
    const [scrollTop, setScrollTop] = useState<number>(0);
    const [scrollLeft, setScrollLeft] = useState<number>(0);

    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    const windowWidth = useRef(window.innerWidth);
    const onWindowResize = (): void => {
        if (window.innerWidth !== windowWidth.current) {
            setShowTooltip(false);
            windowWidth.current = window.innerWidth;
        }
    };

    useEffect(() => {
        window.addEventListener("resize", onWindowResize);
        return (): void => {
            window.removeEventListener("resize", onWindowResize);
        };
    }, []);

    useIsomorphicLayoutEffect(() => {
        if (showTooltip && anchorRef.current) {
            containerTop
                ? setScrollTop(containerTop)
                : setScrollTop(-document.documentElement.scrollTop);
            containerLeft
                ? setScrollLeft(containerLeft)
                : setScrollLeft(-document.documentElement.scrollLeft);
            setAnchorPosition(anchorRef.current.getBoundingClientRect());
        }
    }, [showTooltip]);

    const [isAnchorVisible] = useIntersectionObserver(
        {
            rootMargin: "0px",
            threshold: 1,
            once: true,
        },
        anchorRef
    );

    useEffect(() => {
        if (show && isAnchorVisible) {
            setTimeout(() => {
                setShowTooltip(true);
                onShow?.();
            }, delay);

            if (duration) {
                setTimeout(() => setShowTooltip(false), duration);
            }
        }
    }, [show, isAnchorVisible]);

    return (
        <>
            {showTooltip && (
                <TooltipPortal
                    text={text}
                    placement={placement}
                    anchorPosition={anchorPosition}
                    offset={offset}
                    scrollTop={scrollTop}
                    scrollLeft={scrollLeft}
                    parentRef={parentRef}
                />
            )}
            <div data-testid="tooltip-anchor" ref={anchorRef}>
                {children}
            </div>
        </>
    );
}
