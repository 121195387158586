import React, { useRef, useState } from "react";
import { UrlOverlay } from "web/react/components/url-overlay";
import { useRothkoFetcher } from "web/react/hooks/use-rothko-fetcher";
import { useUrlData } from "web/react/hooks/use-url-data/use-url-data";
import { InStockProductPage } from "web/react/pages/product/in-stock/in-stock-product-page/in-stock-product-page";
import { OutOfStockProductPage } from "web/react/pages/product/out-of-stock/out-of-stock-product-page/out-of-stock-product-page";
import analytics from "web/script/analytics/analytics";
import { ProductOverlaySerializer } from "web/types/serializers";
import { getOverlayDecision, getProductSlugFromPath, handlePageAnalytics } from "./utils";

export type PageType = "pdp" | "feed" | "home" | "editorial";
interface ProductOverlayProps {
    parentRef?: React.RefObject<HTMLElement>;
    pageType?: PageType;
}

export function ProductOverlay({
    parentRef,
    pageType = "pdp",
}: ProductOverlayProps): React.ReactElement | null {
    const [productData, setProductData] = useState<ProductOverlaySerializer | null>(null);
    const [fetch, isLoading] = useRothkoFetcher<ProductOverlaySerializer>(
        "modules/product/product_overlay"
    );
    const urlData = useUrlData();
    const composedOverlayDecision = getOverlayDecision(pageType);

    const productOverlayRef = useRef(null);

    async function load(controller: AbortController): Promise<void> {
        if (!urlData) {
            return;
        }

        const { newURL: url } = urlData;
        const productSlug = getProductSlugFromPath(url);
        const linkId = url.searchParams.get("link_id");

        return fetch(
            {
                slug: productSlug,
                link_id: linkId,
            },
            controller.signal
        ).then((data) => {
            setProductData(data);
            handlePageAnalytics(data);
        });
    }

    // Trigger an analytics event each time the user returns to the browser tab with the product overlay opened after an
    // affiliate click. When `is_track_overlay` in the url params is set to true, it means the user has opened an
    // affiliate link and is coming back from the affiliate page.
    // See https://lyst.atlassian.net/browse/CW-2023 for more infos.
    function onFocus(): void {
        if (new URL(window.location.href).searchParams.get("is_track_overlay") === "true") {
            analytics.event("lead_overlay", "impression", "");
        }
    }

    return (
        <UrlOverlay
            parentRef={parentRef}
            load={load}
            getOverlayDecision={composedOverlayDecision}
            isLoading={isLoading}
            onFocus={onFocus}
        >
            {productData?.in_stock && (
                <div data-testid="pdp-in-stock" ref={productOverlayRef}>
                    <InStockProductPage
                        // we need to pass the slug as a key to render a
                        // fresh InStockProductPage each time the data changes
                        key={productData.slug}
                        productLayout={productData.in_stock.product_layout}
                        productArea={productData.in_stock.product_area}
                        isOverlay
                        parentRef={productOverlayRef}
                    />
                </div>
            )}
            {productData?.out_of_stock && (
                <div data-testid="pdp-out-of-stock">
                    <OutOfStockProductPage
                        productLayout={productData.out_of_stock.product_layout}
                        productUrl={productData.product_url}
                        variant={
                            productData?.out_of_stock.product_layout.is_affiliate
                                ? "affiliate"
                                : "icon"
                        }
                        isOverlay
                        data-testid="pdp-out-of-stock"
                    />
                </div>
            )}
        </UrlOverlay>
    );
}
