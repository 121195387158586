import cloneDeep from "lodash/cloneDeep";
import every from "lodash/every";
import isEqual from "lodash/isEqual";
import noop from "lodash/noop";
import { useState } from "react";
import analytics from "web/script/analytics/analytics";
import environment from "web/script/modules/environment";
import { Address } from "web/types/address";
import { BillingShippingInformation } from "web/types/customer-information";
import { FieldData } from "web/types/form";
import { Name } from "web/types/name";
import {
    CheckoutBillingShippingInformationSerializer,
    CheckoutLayoutSerializer,
} from "web/types/serializers";

const country = environment.get("country");

interface UseShippingBillingFormOptions {
    initialData: CheckoutLayoutSerializer;
}

interface BaseShippingBillingFormDetails {
    billingInformation: BillingShippingInformation;
    customerEmail: FieldData;
    isShippingFormComplete: boolean;
    hasShippingFormErrored: boolean;
    onBillingInformationChange: (billingInformation: BillingShippingInformation) => void;
    onCustomerEmailChange: (email: FieldData) => void;
    onShippingInformationChange: (shippingInformation: BillingShippingInformation) => void;
    shippingInformation: BillingShippingInformation;
    showShippingLoadingSpinner: boolean;
    sendShippingFormChangedAnalytics: () => void;
}

interface UseShippingBillingForm extends BaseShippingBillingFormDetails {
    setShippingFormComplete: (complete: boolean) => void;
    setShippingFormErrored: (errored: boolean) => void;
    setShippingLoadingSpinner: (loading: boolean) => void;
    validateShippingBillingForm: () => boolean;
}

export interface CheckoutContextShippingForm extends BaseShippingBillingFormDetails {
    isShippingFormDisabled: boolean;
    onSubmitShippingForm: () => void;
    validateShippingBillingForm: () => boolean;
}

const blankFormData = { error: "", value: "" };
export const CHECKOUT_CONTEXT_SHIPPING_FORM_DEFAULT_DATA: CheckoutContextShippingForm = {
    billingInformation: {
        address: {
            addressLine1: blankFormData,
            addressLine2: blankFormData,
            addressLine3: blankFormData,
            city: blankFormData,
            countryCode: { error: "", value: environment.get("country") },
            region: blankFormData,
            zipCode: blankFormData,
        },
        name: {
            firstName: blankFormData,
            lastName: blankFormData,
        },
        phoneNumber: blankFormData,
    },
    isShippingFormComplete: true,
    isShippingFormDisabled: false,
    hasShippingFormErrored: false,
    onBillingInformationChange: noop,
    onShippingInformationChange: noop,
    onSubmitShippingForm: noop,
    shippingInformation: {
        address: {
            addressLine1: blankFormData,
            addressLine2: blankFormData,
            addressLine3: blankFormData,
            city: blankFormData,
            countryCode: { error: "", value: environment.get("country") },
            region: blankFormData,
            zipCode: blankFormData,
        },
        name: {
            firstName: blankFormData,
            lastName: blankFormData,
        },
        phoneNumber: blankFormData,
    },
    showShippingLoadingSpinner: false,
    customerEmail: blankFormData,
    onCustomerEmailChange: noop,
    sendShippingFormChangedAnalytics: noop,
    validateShippingBillingForm: () => true,
};

function createFormField(value: string | undefined = ""): FieldData {
    return {
        error: "",
        value: value,
    };
}

export function convertBillingShippingInformation(
    serializedBillingInformation: CheckoutBillingShippingInformationSerializer
): BillingShippingInformation {
    return {
        address: {
            addressLine1: createFormField(serializedBillingInformation.address.address_line_1),
            addressLine2: createFormField(serializedBillingInformation.address.address_line_2),
            addressLine3: createFormField(serializedBillingInformation.address.address_line_3),
            city: createFormField(serializedBillingInformation.address.city),
            countryCode: createFormField(
                serializedBillingInformation.address.country_code || country
            ),
            region: createFormField(serializedBillingInformation.address.region_code),
            zipCode: createFormField(serializedBillingInformation.address.zip_code),
        },
        name: {
            firstName: createFormField(serializedBillingInformation.first_name),
            lastName: createFormField(serializedBillingInformation.last_name),
        },
        phoneNumber: createFormField(serializedBillingInformation.phone_number),
    };
}

export function convertBillingShippingInformationToSerializer(
    billingInformation: BillingShippingInformation
): CheckoutBillingShippingInformationSerializer {
    return {
        address: {
            address_line_1: billingInformation.address.addressLine1.value,
            address_line_2: billingInformation.address.addressLine2.value,
            address_line_3: billingInformation.address.addressLine3.value,
            city: billingInformation.address.city.value,
            country_code: billingInformation.address.countryCode.value,
            region_code: billingInformation.address.region.value,
            zip_code: billingInformation.address.zipCode.value,
        },
        first_name: billingInformation.name.firstName.value,
        last_name: billingInformation.name.lastName.value,
        phone_number: billingInformation.phoneNumber.value,
    };
}

function useShippingBillingForm({
    initialData,
}: UseShippingBillingFormOptions): UseShippingBillingForm {
    const [isShippingFormComplete, setShippingFormComplete] = useState<boolean>(
        initialData.is_shipping_complete
    );
    const [showShippingLoadingSpinner, setShippingLoadingSpinner] = useState<boolean>(false);
    const [hasShippingFormErrored, setShippingFormErrored] = useState<boolean>(false);
    const [billingInformation, setBillingInformation] = useState<BillingShippingInformation>(
        convertBillingShippingInformation(initialData.billing_information)
    );
    const [shippingInformation, setShippingInformation] = useState<BillingShippingInformation>(
        convertBillingShippingInformation(initialData.shipping_information)
    );
    const [customerEmail, setCustomerEmail] = useState<FieldData>({
        value: initialData.email || "",
        error: "",
    });

    const [isShippingAnalyticSent, setIsShippingAnalyticSent] = useState(false);

    function onBillingInformationChange(billingInformation: BillingShippingInformation): void {
        setBillingInformation(billingInformation);
        trackShippingFormChanged();
    }

    function onShippingInformationChange(shippingInformation: BillingShippingInformation): void {
        setShippingInformation(shippingInformation);
        trackShippingFormChanged();
    }

    function onCustomerEmailChange(email: FieldData): void {
        setCustomerEmail(email);
        trackShippingFormChanged();
    }

    function trackShippingFormChanged(): void {
        const hasBillingChanged = !isEqual(
            billingInformation,
            convertBillingShippingInformation(initialData.billing_information)
        );
        const hasShippingChanged = !isEqual(
            shippingInformation,
            convertBillingShippingInformation(initialData.shipping_information)
        );
        const hasEmailChanged = customerEmail.value !== initialData.email;

        if (hasBillingChanged || hasShippingChanged || hasEmailChanged) {
            sendShippingFormChangedAnalytics();
        }
    }

    function sendShippingFormChangedAnalytics(): void {
        // Only to be fired once per shipping form screen view
        if (!isShippingAnalyticSent) {
            analytics.event("shipping_form", "changed");
            setIsShippingAnalyticSent(true);
        }
    }

    function validateShippingBillingForm(): boolean {
        const validatedCustomerEmail = validateFieldData(customerEmail);
        setCustomerEmail(validatedCustomerEmail.fieldData);

        const validatedShippingInformation =
            validateBillingShippingInformation(shippingInformation);
        setShippingInformation(validatedShippingInformation.billingShippingInformation);

        const validatedBillingInformation = validateBillingShippingInformation(billingInformation);
        setBillingInformation(validatedBillingInformation.billingShippingInformation);

        const isValid = every(
            [validatedCustomerEmail, validatedShippingInformation, validatedBillingInformation],
            (element) => element.isValid
        );

        return isValid;
    }

    function validateBillingShippingInformation(
        shippingBillingInformation: BillingShippingInformation
    ): {
        billingShippingInformation: BillingShippingInformation;
        isValid: boolean;
    } {
        const newBillingShippingInformation = cloneDeep(shippingBillingInformation);

        const validatedAddress = validateAddress(shippingBillingInformation.address);
        const validatedName = validateName(shippingBillingInformation.name);
        const validatedPhoneNumber = validateFieldData(shippingBillingInformation.phoneNumber);

        newBillingShippingInformation.address = validatedAddress.address;
        newBillingShippingInformation.name = validatedName.name;
        newBillingShippingInformation.phoneNumber = validatedPhoneNumber.fieldData;

        const isValid = every(
            [validatedAddress, validatedName, validatedPhoneNumber],
            (element) => element.isValid
        );

        return { billingShippingInformation: newBillingShippingInformation, isValid };
    }

    function validateName(name: Name): {
        name: Name;
        isValid: boolean;
    } {
        let nameKey: keyof Name;
        let isValid = true;
        const newName = cloneDeep(name);

        for (nameKey in name) {
            let field = name[nameKey];
            let newNameField = newName[nameKey];

            let validatedField = validateFieldData(field);

            newNameField.error = validatedField.fieldData.error;
            isValid = isValid && validatedField.isValid;
        }

        return { name: newName, isValid };
    }

    function validateAddress(address: Address): {
        address: Address;
        isValid: boolean;
    } {
        let addressKey: keyof Address;
        let isValid = true;

        let newAddress = cloneDeep(address);

        for (addressKey in address) {
            let field = address[addressKey];
            let newAddressField = newAddress[addressKey];

            let validatedField = validateFieldData(field);

            newAddressField.error = validatedField.fieldData.error;
            isValid = isValid && validatedField.isValid;
        }

        return { address: newAddress, isValid };
    }

    function validateFieldData(fieldData: FieldData): {
        fieldData: FieldData;
        isValid: boolean;
    } {
        let isValid = true;
        let newFieldData = cloneDeep(fieldData);

        if (fieldData.error) {
            isValid = false;
        } else if (fieldData.value === "" && fieldData.required) {
            isValid = false;
            newFieldData.error = fieldData.required();
        }

        return { fieldData: newFieldData, isValid };
    }

    return {
        billingInformation,
        customerEmail,
        isShippingFormComplete,
        hasShippingFormErrored,
        onBillingInformationChange,
        onCustomerEmailChange,
        onShippingInformationChange,
        setShippingFormComplete,
        setShippingFormErrored,
        setShippingLoadingSpinner,
        shippingInformation,
        showShippingLoadingSpinner,
        sendShippingFormChangedAnalytics,
        validateShippingBillingForm,
    };
}

export default useShippingBillingForm;
