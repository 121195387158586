import clsx from "clsx";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector as useReduxSelector } from "react-redux";
import { KevelCampaignControl } from "web/react/components/kevel-campaign-control/kevel-campaign-control";
import LoadingSpinner from "web/react/components/loading-spinner/loading-spinner";
import { PaginationProgressBar } from "web/react/components/pagination-progress-bar/pagination-progress-bar";
import { Button } from "web/react/emo/button";
import { Text } from "web/react/emo/text";
import { VStack } from "web/react/emo/v-stack";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import { useIntersectionObserver } from "web/react/hooks/use-intersection-observer/use-intersection-observer";
import { setLoading } from "web/redux/ducks/feed-show-more";
import { ReduxStoreState } from "web/redux/store";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import environment from "web/script/modules/environment";
import { numberFormat } from "web/script/modules/formats";
import globals from "web/script/modules/globals";
import history from "web/script/utils/history";
import * as styles from "./feeds-show-more.css";

export function FeedsShowMore(): JSX.Element {
    const {
        currentPage,
        visibleProductCount,
        totalProductCount,
        showMoreNextUrl,
        retailerSlug,
        isLoading,
    } = useReduxSelector((state: ReduxStoreState) => state.feedShowMore);
    const buttonRef = useRef<HTMLDivElement | null>(null);
    const kevelCampaignControlRef = useRef<HTMLDivElement | null>(null);
    const { isMobileViewport } = useDomViewport();
    const enableInfiniteScroll = useMemo(() => {
        if (!currentPage || currentPage < 5) {
            return environment.getFeature("act_feeds_infinite_scroll");
        }

        return false;
    }, [currentPage]);

    const [isButtonVisible] = useIntersectionObserver(
        {
            threshold: 0,
            rootMargin: isMobileViewport ? "200px 0px 0px 0px" : "600px 0px 0px 0px",
            // if the flag is not enabled then only fire once since we can't conditionally call the hook
            // but at least we can not fire the observer
            once: !enableInfiniteScroll,
        },
        buttonRef
    );
    // Boolean to keep track of calling the `showMore` in infinite scroll mode
    const [infiniteScrollCalled, setInfiniteScrollCalled] = useState(false);

    const dispatch = useDispatch();

    // "Showing {current_product_count} of {total_product_count}"
    const messageText =
        totalProductCount > 0 || visibleProductCount === 0
            ? gettext("feed_pagination.summary_showing_products", {
                  current_product_count: numberFormat(visibleProductCount),
                  total_product_count: numberFormat(totalProductCount),
              })
            : "";

    const showMore = useCallback((): void => {
        if (!showMoreNextUrl || isLoading) {
            return;
        }

        const pageViewId = history.getCurrentState().pageViewId;
        history.pushState(new URL(showMoreNextUrl, globals.window.location.href), {
            saveScrollPosition: false,
            data: { pageViewId },
        });
        dispatch(setLoading(true));
    }, [dispatch, showMoreNextUrl, isLoading]);

    // ENRICH-3344: Enable infinite scroll on feeds
    useEffect(() => {
        if (isButtonVisible && enableInfiniteScroll && !infiniteScrollCalled) {
            kevelCampaignControlRef.current?.click?.();
            setInfiniteScrollCalled(true);
        }

        // There is a race condition where the `isButtonVisible` and `isLoading`
        // are not getting updated at the same time.
        // Initially they are both `false`
        // after the `ShowMore` element is in viewport reported by the Intersection Observer
        // we set the `isButtonVisible` to `true` and then the `showMore` is called
        // which sets the `isLoading` to `true` as well.
        // However when the `isLoading` is set back to `false` the feed haven't been yet updated
        // therefore the `isButtonVisible` is still true (since the `ShowMore` element is still in viewport)
        // then we will call the `showMore` again and load another page which is not desirable.
        // By having this boolean variable we can control this by checking when `isButtonVisible` updates
        // and then allow the infinite scroll functionality to work
        if (!isButtonVisible) {
            setInfiniteScrollCalled(false);
        }
    }, [isButtonVisible, enableInfiniteScroll, infiniteScrollCalled, showMore]);

    // Don't show the component at all
    // if we are having 1 page of results only
    if (currentPage === 1 && !showMoreNextUrl) {
        return <div data-testid="show-more-empty" className={styles.emptyWrapper} />;
    }

    return (
        <div ref={buttonRef} data-testid="show-more" className={styles.wrapper}>
            <Text className={styles.progressMessageText} key={"show_more"} textStyle={"small-link"}>
                {messageText}
            </Text>
            <PaginationProgressBar
                currentNumber={visibleProductCount}
                maximumNumber={totalProductCount}
            />
            {showMoreNextUrl && (
                <KevelCampaignControl
                    ref={kevelCampaignControlRef}
                    contextType={"feeds_show_more"}
                    beforeOnClick={() => {
                        if (enableInfiniteScroll) {
                            analytics.event("feeds_show_more", "infinite_scroll", "trigger");
                        } else {
                            analytics.event("feeds_show_more", "click_show_more_button", "click");
                        }
                    }}
                    defaultAction={showMore}
                    analyticsEventLabel={"feeds_show_more"}
                    options={{
                        action: "feeds_show_more_click",
                        heimdallFlags: ["eac_desktop_feeds_show_more_signup_block"],
                        retailerSlug,
                    }}
                    continueOptionHref={enableInfiniteScroll ? "" : showMoreNextUrl}
                >
                    <VStack align="center" justify="center" spacing="xs">
                        {/* onClick is handled by KevelCampaignControl wrapper */}
                        {!enableInfiniteScroll && (
                            <Button
                                title={gettext("general.show_more")}
                                variant={"secondary"}
                                height={"fit-content"}
                                width={"full"}
                                href={showMoreNextUrl}
                            />
                        )}
                        <div className={clsx(styles.loadingWrapper, styles.inlineLoadingWrapper)}>
                            {isLoading && <LoadingSpinner />}
                        </div>
                    </VStack>
                </KevelCampaignControl>
            )}
        </div>
    );
}
