import React, { Dispatch, SetStateAction, createContext, useContext, useState } from "react";
import environment from "web/script/modules/environment";
import storage from "web/script/utils/storage";

interface FeedContextProps {
    feedFetched: boolean;
    setFeedFetched: Dispatch<SetStateAction<boolean>>;
    feedTooltipCount?: number;
}

const FeedContext = createContext<FeedContextProps>({
    feedFetched: false,
    setFeedFetched: () => {},
    feedTooltipCount: 0,
});

interface FeedContextProviderProps {
    children: React.ReactNode;
}

export function FeedContextProvider({ children }: FeedContextProviderProps): React.ReactElement {
    const [feedFetched, setFeedFetched] = useState(true);

    let tooltipCount = 0;
    if (environment.getFeature("mg_wishlist_tooltip_experiment")) {
        tooltipCount = storage.get("feedTooltipCount", 0);
    }

    const value = {
        feedFetched,
        setFeedFetched,
        feedTooltipCount: tooltipCount,
    };
    return <FeedContext.Provider value={value}>{children}</FeedContext.Provider>;
}

export function useFeedContext(): FeedContextProps {
    return useContext(FeedContext);
}
