import React, { useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { CSSTransitionProps } from "react-transition-group/CSSTransition";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { Text } from "web/react/emo/text";
import { useIntersectionObserver } from "web/react/hooks/use-intersection-observer/use-intersection-observer";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import * as styles from "./toast.css";

export interface ToastProps {
    message: string;
    duration?: number;
    onDismiss?: (gesture: "close-button" | "escape" | "timeout") => void;
}

export const Toast = ({ message, duration = 5000, onDismiss }: ToastProps): JSX.Element | null => {
    const [hidden, setHidden] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const [isVisible] = useIntersectionObserver(
        {
            threshold: 0,
            rootMargin: "0px",
            once: true,
        },
        ref
    );

    useEffect(() => {
        if (isVisible) {
            analytics.event("toast", "viewed");
        }
    }, [isVisible]);

    useEffect(() => {
        const onKeyDown = (event: KeyboardEvent): void => {
            if (event.key === "Escape") {
                setHidden(true);
                onDismiss?.("escape");
            }
        };
        window.addEventListener("keydown", onKeyDown);
        return () => {
            window.removeEventListener("keydown", onKeyDown);
        };
    }, [onDismiss]);

    useEffect(() => {
        if (duration) {
            const timer = setTimeout(() => {
                onDismiss?.("timeout");
            }, duration);
            return () => clearTimeout(timer);
        }
    }, [onDismiss, duration]);

    const onCloseButtonClick = (): void => {
        setHidden(true);
        onDismiss?.("close-button");

        analytics.event("toast", "clicked", "close_button");
    };

    const onTextClick = (): void => {
        analytics.event("toast", "clicked", "text");
    };

    if (hidden) {
        return null;
    }

    const text = (
        <span
            onClick={onTextClick}
            dangerouslySetInnerHTML={{
                __html: gettext(message),
            }}
        />
    );

    return (
        <div ref={ref} className={styles.toast}>
            <Text textStyle={{ sm: "body-2", md: "body-1" }}>{text}</Text>
            <button onClick={onCloseButtonClick} className={styles.button} aria-label="Close">
                <SVGIcon className={styles.icon} name={"close"}></SVGIcon>
            </button>
        </div>
    );
};

export const ToastTransition = (props: CSSTransitionProps): JSX.Element | null => (
    <CSSTransition
        in
        appear
        classNames={{
            appear: styles.enter,
            appearActive: styles.enterActive,
            enter: styles.enter,
            enterActive: styles.enterActive,
            exit: styles.exit,
            exitActive: styles.exitActive,
        }}
        {...props}
    />
);
